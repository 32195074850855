import React from 'react';
import { FaMapMarkerAlt, FaPhoneAlt, FaAt, FaLaptop } from 'react-icons/fa';
import './ContactUs.css'; // Import the CSS file for custom styles

const ContactUs = () => {
  return (
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-md-12">
          <div className="card shadow-lg rounded-lg border-0">
            <div className="card-body p-4">
              <h2 className="text-center mb-4" style={{ fontFamily: 'Arial, sans-serif' }}>Contact Us</h2>
              <div className="row">
                {/* Left Section - Contact Info */}
                <div className="col-md-6 mb-4">
                  <h5 className="text-primary">State Management Unit - Rural Housing</h5>
                  <div className="contact-info">
                    <p>
                      <FaMapMarkerAlt /> <span>4th Floor, CIDCO Bhavan – South Wing, CBD Belapur, Navi Mumbai- 400614, Maharashtra.</span>
                    </p>
                    <p>
                      <FaPhoneAlt /> <span>Contact: 022-27561824</span>
                    </p>
                    <p>
                      <FaAt /> <span>Email: <a href="mailto:directoriayruralhousing@gmail.com">directoriayruralhousing@gmail.com</a></span>
                    </p>
                    <p>
                      <FaLaptop /> <span>Website: <a href="https://www.mahaawaas.org" target="_blank" rel="noopener noreferrer">www.mahaawaas.org</a></span>
                    </p>
                  </div>
                </div>

                {/* Right Section - Google Map */}
                <div className="col-md-6">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15087.699506506702!2d73.0390647!3d19.0230317!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xaca08906cc27e11e!2sCity%20and%20Industrial%20Development%20Corporation%20of%20Maharashtra%20Limited!5e0!3m2!1sen!2sin!4v1606385084405!5m2!1sen!2sin"
                    width="100%" height="350" frameBorder="0" style={{ border: '0' }} allowFullScreen="" aria-hidden="false" tabIndex="0"
                    title="Google Map Location"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const InnovationsTwo = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Innovations');
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/Innovations/innovationCount`);

        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        setData(data);
        setFilteredData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const districts = data && [...new Set(data.map((item) => item.district))];

  const filterData = useCallback(() => {
    const filtered = data.filter((item) => {
      const matchesDistrict = !district || item.district === district;
      const matchesSearch = searchTerm
        ? Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : true;

      return matchesDistrict && matchesSearch;
    });

    setFilteredData(filtered);
  }, [data, district, searchTerm]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
    if (selected === 'Innovations') {
      navigate('/innovations');
    } else if (selected === 'InnovationOne') {
      navigate('/innovationsOne');
    } else if (selected === 'Innovationtwo') {
      navigate('/innovationsTwo');
    }
  };

  const calculateTotals = () => {
    let totals = {
      bahumajliImarti: 0,
      gruhsankule: 0,
      bahumajliGruhsankule: 0,
      cropShop: 0,
      demoHouseTaluka: 0,
      landBank: 0,
      sandBank: 0,
      gharkulMart: 0,
      valulaParyay: 0,
      gruhkarj: 0,
      aadarshGharkul: 0,
      newBuildTechnology: 0,
      precast: 0,
      logo: 0
    };
  
    filteredData.forEach((item) => {
      totals.bahumajliImarti += parseInt(item.tbl_bahumajli_imarti || 0);
      totals.gruhsankule += parseInt(item.tbl_gruhsankule || 0);
      totals.bahumajliGruhsankule += parseInt(item.tbl_bahumajli_gruhsankule || 0);
      totals.cropShop += parseInt(item.tbl_crop_shop || 0);
      totals.demoHouseTaluka += parseInt(item.tbl_demo_house_taluka || 0);
      totals.landBank += parseInt(item.tbl_land_bank || 0);
      totals.sandBank += parseInt(item.tbl_sand_bank || 0);
      totals.gharkulMart += parseInt(item.tbl_gharkul_mart || 0);
      totals.valulaParyay += parseInt(item.tbl_valula_paryay || 0);
      totals.gruhkarj += parseInt(item.tbl_gruhkarj || 0);
      totals.aadarshGharkul += parseInt(item.tbl_aadarsh_gharkul || 0);
      totals.newBuildTechnology += parseInt(item.tbl_new_build_technology || 0);
      totals.precast += parseInt(item.tbl_precast || 0);
      totals.logo += parseInt(item.tbl_logo || 0);
    });
  
    return totals;
  };

  const exportToExcel = () => {
    const mappedData = filteredData.map((item, idx) => ({
      'Sr No': idx + 1,
      District: item.district || '0',
      'Bahumajli Imarti': item.tbl_bahumajli_imarti || '0',
      'Gruhsankule': item.tbl_gruhsankule || '0',
      'Bahumajli Gruhsankule': item.tbl_bahumajli_gruhsankule || '0',
      'Crop Shop': item.tbl_crop_shop || '0',
      'Demo House Taluka': item.tbl_demo_house_taluka || '0',
      'Land Bank': item.tbl_land_bank || '0',
      'Sand Bank': item.tbl_sand_bank || '0',
      'Gharkul Mart': item.tbl_gharkul_mart || '0',
      'Valula Paryay': item.tbl_valula_paryay || '0',
      'Gruhkarj': item.tbl_gruhkarj || '0',
      'Aadarsh Gharkul': item.tbl_aadarsh_gharkul || '0',
      'New Build Technology': item.tbl_new_build_technology || '0',
      'Precast': item.tbl_precast || '0',
      'Logo': item.tbl_logo || '0',
    }));
    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Innovation Data');
    XLSX.writeFile(wb, 'Innovation_Data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map((item, idx) => [
      idx + 1,
      item.district || '0',
      item.tbl_bahumajli_imarti,
      item.tbl_gruhsankule,
      item.tbl_bahumajli_gruhsankule,
      item.tbl_crop_shop,
      item.tbl_demo_house_taluka,
      item.tbl_land_bank,
      item.tbl_sand_bank,
      item.tbl_gharkul_mart,
      item.tbl_valula_paryay,
      item.tbl_gruhkarj,
      item.tbl_aadarsh_gharkul,
      item.tbl_new_build_technology,
      item.tbl_precast,
      item.tbl_logo,
    ]);

    doc.autoTable({
      head: [
        [
          'Sr No',
          'District',
          'Bahumajli Imarti',
          'Gruhsankule',
          'Bahumajli Gruhsankule',
          'Crop Shop',
          'Demo House Taluka',
          'Land Bank',
          'Sand Bank',
          'Gharkul Mart',
          'Valula Paryay',
          'Gruhkarj',
          'Aadarsh Gharkul',
          'New Build Technology',
          'Precast',
          'Logo',
        ],
      ],
      body: tableData,
    });
    doc.save('InnovationReport.pdf');
  };

  const totals = calculateTotals();

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-4">
          <div className="filters p-3" style={{ backgroundColor: '#e6e6fa', borderRadius: '8px' }}>
            <h5>Filters</h5>
            <Form>
                <Form.Group controlId="filterSelect" className="mb-3">
                                              <Form.Label>Select Report</Form.Label>
                                              <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                                                {/* <option value="">All Reports</option> */}
                                                <option value="Innovatiom">भूमिहीन लाभार्थ्यांना जागा उपलब्ध करून देण्यासाठीचे नाविन्यपुर्ण उपक्रम</option>
                                                <option value="InnovationOne">घरकुले वेळेत पुर्ण होण्यासाठीचे नाविन्यपुर्ण उपक्रम</option>
                                                <option value="Innovationtwo">इतर नाविन्य पूर्ण उपक्रम</option>
                                                
                                              </Form.Control>
                                            </Form.Group>
                
              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control as="select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                  <option value="">All Districts</option>
                  {districts.map((districtName, idx) => (
                    <option key={idx} value={districtName}>
                      {districtName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <h4>इतर नाविन्य पूर्ण उपक्रम</h4>
            <div className="d-flex align-items-center">
              <InputGroup className="me-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" className="me-2" onClick={exportToExcel}>
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>
                Export to PDF
              </Button>
            </div>
          </div>

          {loading ? (
            <Spinner animation="border" className="d-block mx-auto" />
          ) : (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 1 }}>
                  <tr>
                    <th>अ. क्र </th>
                    <th>जिल्हा  </th>
                    {/* <th>बहुमजली इमारती</th>
                    <th>गृहसंकुले</th>
                    <th>बहुमजली गृहसंकुले</th> */}
                    <th>कॉप शॉप</th>
                    {/* <th>डेमो हाऊस तालुका</th> */}
                    {/* <th>लँड बँक</th>
                    <th>सॅण्ड बँक</th>
                    <th>घरकुल मार्ट</th> */}
                    <th>वाळूला पर्याय</th>
                    <th>आदर्श घरकुले</th>
                    <th>नविन बांधकाम तंत्रज्ञान</th>
                    <th>प्रीकास्ट टेक्नॉलॉजी</th>
                    <th>घरावर लोगो</th>
                    <th>गृहकर्ज</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    <>
                      {filteredData.map((item, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.district || '0'}</td>
                          {/* <td>{item.tbl_bahumajli_imarti || '0'}</td>
                          <td>{item.tbl_gruhsankule || '0'}</td>
                          <td>{item.tbl_bahumajli_gruhsankule || '0'}</td> */}
                          <td>{item.tbl_crop_shop || '0'}</td>
                          {/* <td>{item.tbl_demo_house_taluka || '0'}</td> */}
                          {/* <td>{item.tbl_land_bank || '0'}</td> */}
                          {/* <td>{item.tbl_sand_bank || '0'}</td> */}
                          {/* <td>{item.tbl_gharkul_mart || '0'}</td> */}
                          <td>{item.tbl_valula_paryay || '0'}</td>
                          <td>{item.tbl_aadarsh_gharkul || '0'}</td>
                          <td>{item.tbl_new_build_technology || '0'}</td>
                          <td>{item.tbl_precast || '0'}</td>
                          <td>{item.tbl_logo || '0'}</td>
                          <td>{item.tbl_gruhkarj || '0'}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="2" className="text-right">
                          <strong>Total</strong>
                        </td>
                        <td><strong>{totals.cropShop}</strong></td>
                        <td><strong>{totals.valulaParyay}</strong></td>
                        <td><strong>{totals.aadarshGharkul}</strong></td>

                        <td><strong>{totals.newBuildTechnology}</strong></td>
                        <td><strong>{totals.precast}</strong></td>
                        <td><strong>{totals.logo}</strong></td>
                        <td><strong>{totals.gruhkarj}</strong></td>








                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="16" className="text-center">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default InnovationsTwo;
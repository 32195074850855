import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaFilter, FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const ThakkarBappaYojana = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('ThakkarBappaYojana');

  const navigate = useNavigate();

  // Fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'http://145.223.19.41:8090/mahaAawaasReport/api/convergence/thakkarBappa?page=1&size=100'
        );
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        setData(result);
        setFilteredData(result);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const districts = data && Array.isArray(data) ? [...new Set(data.map(item => item.districtName))] : [];
  const talukas = district ? [...new Set(data.filter(item => item.districtName === district).map(item => item.talukaName))] : [];

  const filterData = useCallback(() => {
    setFilteredData(data.filter(item => {
      return (
        (!searchTerm || item.thakkarBappaId.toString().includes(searchTerm)) &&
        (!district || item.districtName === district) &&
        (!taluka || item.talukaName === taluka)
      );
    }));
  }, [data, searchTerm, district, taluka]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
    if (selected === 'allReports') {
      navigate('/Convergence');
    }else if (selected === 'SwachhBharatAbhiyan') {
      navigate('/SwachhBharatAbhiyan');
    } else if (selected === 'JaljivanMission') {
      navigate('/JaljivanMission');
    } else if (selected === 'DalitVastiSudharYojana') {
      navigate('/DalitVastiSudharYojana');
    } else if (selected === 'GraminJivannotyaAbhiyan') {
      navigate('/GraminJivannotyaAbhiyan');
    } else if (selected === 'GrampanchayatSes') {
      navigate('/GrampanchayatSes');
    } else if (selected === 'JilhaParishadSes') {
      navigate('/JilhaParishadSes');
    } else if (selected === 'PanchayatSamitiSes') {
      navigate('/PanchayatSamitiSes');
    } else if (selected === 'SaubhagyaYojana') {
      navigate('/SaubhagyaYojana');
    } else if (selected === 'ThakkarBappaYojana') {
      navigate('/ThakkarBappaYojana');
    }
  };

  // Export to Excel
  const exportToExcel = () => {
    const mappedData = filteredData.map((item, idx) => ({
      "Sr No": idx + 1,
      "Thakkar Bappa ID": item.thakkarBappaId || 'N/A',
      "Allgharkul Rajya Yojana": item.allgharkulRajyaYojana,
      "Total Number": item.totalNumber,
      "Labh Swaroop": item.labhSwaroop,
      "Created Date": item.createdDatetime,
      "District": item.districtName || 'N/A',
      "Taluka": item.talukaName || 'N/A',
      "Status": item.active ? 'Active' : 'Inactive',
    }));

    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Thakkar Bappa Yojana Data');
    XLSX.writeFile(wb, 'Thakkar_Bappa_Yojana_Data.xlsx');
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map((item, idx) => [
      idx + 1,
      item.thakkarBappaId || 'N/A',
      item.allgharkulRajyaYojana,
      item.totalNumber,
      item.labhSwaroop,
      item.createdDatetime,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.active ? 'Active' : 'Inactive',
    ]);

    doc.setFont("helvetica", "normal");
    doc.autoTable({
      head: [
        ['Sr No', 'Thakkar Bappa ID', 'Allgharkul Rajya Yojana', 'Total Number', 'Labh Swaroop', 'Created Date', 'District', 'Taluka', 'Status']
      ],
      body: tableData,
      styles: { fontSize: 10, cellPadding: 2, valign: 'middle' },
      headStyles: { fillColor: [100, 150, 255], fontSize: 12, textColor: 255 },
      margin: { top: 20, left: 10, right: 10 }
    });

    doc.save('Thakkar_Bappa_Yojana_Data.pdf');
  };

  // Total calculations
  const totalAllgharkulRajyaYojana = filteredData.reduce((acc, item) => acc + item.allgharkulRajyaYojana, 0);
  const totalNumber = filteredData.reduce((acc, item) => acc + item.totalNumber, 0);

  return (
    <Container fluid>
      <Row>
        {/* Sidebar Filters Section */}
        <Col md={2} className="p-4">
          <div className="filters p-3" style={{ backgroundColor: '#e6e6fa', borderRadius: '8px' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                <option value="allReports">All Reports</option>
                <option value="SwachhBharatAbhiyan">Swachh Bharat Abhiyan</option>
                  <option value="UjjwalaYojana">UjjwalaYojana</option>
                  <option value="JaljivanMission">JaljivanMission</option>
                  <option value="DalitVastiSudharYojana">DalitVastiSudharYojana</option>
                  <option value="GraminJivannotyaAbhiyan">GraminJivannotyaAbhiyan</option>
                  <option value="GrampanchayatSes">GrampanchayatSes</option>
                  <option value="JilhaParishadSes">JilhaParishadSes</option>
                  <option value="PanchayatSamitiSes">PanchayatSamitiSes</option>
                  <option value="SaubhagyaYojana">SaubhagyaYojana</option>
                  <option value="ThakkarBappaYojana">ThakkarBappaYojana</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control as="select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                  <option value="">All Districts</option>
                  {districts.map((districtName, idx) => (
                    <option key={idx} value={districtName}>
                      {districtName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  as="select"
                  value={taluka}
                  onChange={(e) => setTaluka(e.target.value)}
                  disabled={!district}
                >
                  <option value="">All Talukas</option>
                  {talukas.map((talukaName, idx) => (
                    <option key={idx} value={talukaName}>
                      {talukaName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        {/* Table and Controls Section */}
        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-4">
            <div><span>Total Entries: {filteredData.length}</span></div>
            <div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search by Thakkar Bappa ID"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text><FaSearch /></InputGroup.Text>
              </InputGroup>
              <Button variant="primary" onClick={exportToExcel}>Export to Excel</Button>
              <Button variant="success" className="ms-2" onClick={exportToPDF}>Export to PDF</Button>
            </div>
          </div>

          {/* Loading Spinner */}
          {loading ? (
            <Spinner animation="border" variant="primary" />
          ) : (
            <Table bordered hover responsive className="mt-3">
              <thead className="sticky-top bg-light">
                <tr>
                  <th>Sr No</th>
                  <th>Thakkar Bappa ID</th>
                  <th>Allgharkul Rajya Yojana</th>
                  <th>Total Number</th>
                  <th>Labh Swaroop</th>
                  <th>Created Date</th>
                  <th>District</th>
                  <th>Taluka</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((item, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{item.thakkarBappaId}</td>
                      <td>{item.allgharkulRajyaYojana}</td>
                      <td>{item.totalNumber}</td>
                      <td>{item.labhSwaroop}</td>
                      <td>{item.createdDatetime}</td>
                      <td>{item.districtName || 'N/A'}</td>
                      <td>{item.talukaName || 'N/A'}</td>
                      <td>{item.active ? 'Active' : 'Inactive'}</td>
                    </tr>
                  ))
                ) : (
                  <tr><td colSpan="9" className="text-center">No records found</td></tr>
                )}
              </tbody>
            </Table>
          )}

          {/* Totals Section */}
          <div className="d-flex justify-content-end mt-3">
            <strong>Total Allgharkul Rajya Yojana: </strong> {totalAllgharkulRajyaYojana}
            <br />
            <strong>Total Number: </strong> {totalNumber}
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ThakkarBappaYojana;

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const Convergence = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Convergence');
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Set loading to true before starting the fetch
      try {
        const apiBaseUrl = process.env.REACT_APP_API_URL; // Fetch the base URL from the .env file
        const response = await fetch(`${apiBaseUrl}/convergenceReport/allReport`);
        
        if (!response.ok) throw new Error('Failed to fetch data');
        
        const data = await response.json();
        setData(data); // Store the data in state
        setFilteredData(data); // Store the filtered data in state (optional, if you're going to filter later)
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false); // Set loading to false after the fetch is complete
      }
    };
    
    fetchData();
  }, []);
  const districts = data && [...new Set(data.map((item) => item.districtName))];
  const talukas = district
    ? [...new Set(data.filter((item) => item.districtName === district).map((item) => item.talukaName))]
    : [];

  const filterData = useCallback(() => {
    const filtered = data.filter((item) => {
      const matchesDistrict = !district || item.districtName === district;
      const matchesTaluka = !taluka || item.talukaName === taluka;
      const matchesSearch = searchTerm
        ? Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : true;

      return matchesDistrict && matchesTaluka && matchesSearch;
    });

    setFilteredData(filtered);
  }, [data, district, taluka, searchTerm]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
    if (selected === 'allReports') {
      navigate('/Convergence');
    }else if (selected === 'SwachhBharatAbhiyan') {
      navigate('/SwachhBharatAbhiyan');
    } else if (selected === 'JaljivanMission') {
      navigate('/JaljivanMission');
    } else if (selected === 'DalitVastiSudharYojana') {
      navigate('/DalitVastiSudharYojana');
    } else if (selected === 'GraminJivannotyaAbhiyan') {
      navigate('/GraminJivannotyaAbhiyan');
    } else if (selected === 'GrampanchayatSes') {
      navigate('/GrampanchayatSes');
    } else if (selected === 'JilhaParishadSes') {
      navigate('/JilhaParishadSes');
    } else if (selected === 'PanchayatSamitiSes') {
      navigate('/PanchayatSamitiSes');
    } else if (selected === 'SaubhagyaYojana') {
      navigate('/SaubhagyaYojana');
    } else if (selected === 'ThakkarBappaYojana') {
      navigate('/ThakkarBappaYojana');
    }
  };

  const calculateTotals = () => {
    let totals = {
      swachhBharatAbhiyan: 0,
      ujjwalaYojana: 0,
      jalJivanMission: 0,
      saubhagyaYojana: 0,
      graminJivannotya: 0,
      dalitVastiSudhar: 0,
      takkarBappa: 0,
      jilhaParishad: 0,
      panchayatSamiti: 0,
      grampanchayatSamiti: 0,
      csrNidhi: 0
    };
  
    filteredData.forEach((item) => {
      totals.swachhBharatAbhiyan += parseInt(item.tbl_swachya_bharat_abhiyan || 0);
      totals.ujjwalaYojana += parseInt(item.tbl_ujjwala_yojana || 0);
      totals.jalJivanMission += parseInt(item.tbl_jaljivan_mission || 0);
      totals.saubhagyaYojana += parseInt(item.tbl_saubhagya_yojana || 0);
      totals.graminJivannotya += parseInt(item.tbl_gramin_jivannotya || 0);
      totals.dalitVastiSudhar += parseInt(item.tbl_dalit_vasti_sudhar || 0);
      totals.takkarBappa += parseInt(item.tbl_takkar_bappa || 0);
      totals.jilhaParishad += parseInt(item.tbl_jilha_parishad || 0);
      totals.panchayatSamiti += parseInt(item.tbl_panchayat_samiti || 0);
      totals.grampanchayatSamiti += parseInt(item.tbl_grampanchayat_samiti || 0);
      totals.csrNidhi += parseInt(item.tbl_crs_nidhi || 0);
    });
  
    return totals;
  };
  
  const exportToExcel = () => {
    const mappedData = filteredData.map((item, idx) => ({
      'Sr No': idx + 1,
      District: item.districtName || 'N/A',
      Taluka: item.talukaName || 'N/A',
      'Dalit Vasti ID': item.dalitVastiId,
      'Total Projects': item.totalProjects,
      'Total Amount': item.totalAmount,
      'Created Date': item.createdDatetime,
      Status: item.active ? 'Active' : 'Inactive',
    }));
    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Dalit Vasti Sudhar Yojana Data');
    XLSX.writeFile(wb, 'Dalit_Vasti_Sudhar_Yojana_Data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map((item, idx) => [
      idx + 1,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.dalitVastiId,
      item.totalProjects,
      item.totalAmount,
      item.createdDatetime,
      item.active ? 'Active' : 'Inactive',
    ]);

    doc.autoTable({
      head: [
        [
          'Sr No',
          'District',
          'Taluka',
          'Dalit Vasti ID',
          'Total Projects',
          'Total Amount',
          'Created Date',
          'Status',
        ],
      ],
      body: tableData,
    });
    doc.save('stateTotalConvergenceReport.pdf');
  };

  const totals = calculateTotals();

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-4">
          <div className="filters p-3" style={{ backgroundColor: '#e6e6fa', borderRadius: '8px' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="allReports">All Reports</option>
                  <option value="SwachhBharatAbhiyan">Swachh Bharat Abhiyan</option>
                  <option value="UjjwalaYojana">UjjwalaYojana</option>
                  <option value="JaljivanMission">JaljivanMission</option>
                  <option value="DalitVastiSudharYojana">DalitVastiSudharYojana</option>
                  <option value="GraminJivannotyaAbhiyan">GraminJivannotyaAbhiyan</option>
                  <option value="GrampanchayatSes">GrampanchayatSes</option>
                  <option value="JilhaParishadSes">JilhaParishadSes</option>
                  <option value="PanchayatSamitiSes">PanchayatSamitiSes</option>
                  <option value="SaubhagyaYojana">SaubhagyaYojana</option>
                  <option value="ThakkarBappaYojana">ThakkarBappaYojana</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control as="select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                  <option value="">All Districts</option>
                  {districts.map((districtName, idx) => (
                    <option key={idx} value={districtName}>
                      {districtName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  as="select"
                  value={taluka}
                  onChange={(e) => setTaluka(e.target.value)}
                  disabled={!district}
                >
                  <option value="">All Talukas</option>
                  {talukas.map((talukaName, idx) => (
                    <option key={idx} value={talukaName}>
                      {talukaName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <h4>Convergence SchemeWise Count</h4>
            <div className="d-flex align-items-center">
              <InputGroup className="me-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" className="me-2" onClick={exportToExcel}>
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>
                Export to PDF
              </Button>
            </div>
          </div>

          {loading ? (
            <Spinner animation="border" className="d-block mx-auto" />
          ) : (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 1 }}>
                  <tr>
                    <th>Sr No</th>
                    <th>District</th>
                    <th>स्वच्छ भारत अभियान</th>
                    <th>उज्ज्वला योजना  </th>
                    <th>जलजीवन मिशन </th>
                    <th>सौभ्याग्य योजना</th>
                    <th>ग्रामीण जीवणोत्या अभियान</th>
                    <th>दलित वस्ती सुधार योजना  </th>
                    <th>ठक्कर बाप्पा</th>
                    <th>जिह्वा परिषद सेस</th>
                    <th>पंचायत समिती सेस </th>
                    <th>ग्रामपंचायत सेस  </th>
                    <th>सी एस आर निधी </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    <>
                      {filteredData.map((item, idx) => (
                        <tr key={idx}>
  <td>{idx + 1}</td>
  <td>{item.district || 'N/A'}</td>
  <td>{item.tbl_swachya_bharat_abhiyan || 'N/A'}</td>
  <td>{item.tbl_ujjwala_yojana || 'N/A'}</td>
  <td>{item.tbl_jaljivan_mission || 'N/A'}</td>
  <td>{item.tbl_saubhagya_yojana || 'N/A'}</td>
  <td>{item.tbl_gramin_jivannotya || 'N/A'}</td>
  <td>{item.tbl_dalit_vasti_sudhar || 'N/A'}</td>
  <td>{item.tbl_takkar_bappa || 'N/A'}</td>
  <td>{item.tbl_jilha_parishad || 'N/A'}</td>
  <td>{item.tbl_panchayat_samiti || 'N/A'}</td>
  <td>{item.tbl_grampanchayat_samiti || 'N/A'}</td>
  <td>{item.tbl_crs_nidhi || 'N/A'}</td>
</tr>
                      ))}
<tr>
  <td colSpan="2" className="text-right">
    <strong>Total</strong>
  </td>
  <td><strong>{totals.swachhBharatAbhiyan}</strong></td>
  <td><strong>{totals.ujjwalaYojana}</strong></td>
  <td><strong>{totals.jalJivanMission}</strong></td>
  <td><strong>{totals.saubhagyaYojana}</strong></td>
  <td><strong>{totals.graminJivannotya}</strong></td>
  <td><strong>{totals.dalitVastiSudhar}</strong></td>
  <td><strong>{totals.takkarBappa}</strong></td>
  <td><strong>{totals.jilhaParishad}</strong></td>
  <td><strong>{totals.panchayatSamiti}</strong></td>
  <td><strong>{totals.grampanchayatSamiti}</strong></td>
  <td><strong>{totals.csrNidhi}</strong></td>
</tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="16" className="text-center">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Convergence;

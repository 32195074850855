import React from 'react';
import { FormControl, InputLabel, MenuItem, Select, Card, Typography, Grid } from '@mui/material';

const Sidebar = ({ districts, talukas, villages, handleSelect, selected }) => {
  return (
    <aside
      style={{
        padding: '30px',
        backgroundColor: '#e6e6fa', // Custom blue background color
        color: 'white',
        borderRadius: '8px',
        height: '60vh',
        
        display: 'flex',
        flexDirection: 'column',
        position: 'sticky',
        top: '0',
        marginTop: '20px',  // Top margin added
        marginBottom: '20px', // Bottom margin added
      }}
    >
      <Typography variant="h5" gutterBottom sx={{ color: '#000000', textAlign: 'center' }}>
  Navigation
</Typography>

      {/* District Dropdown */}
      <Card
        sx={{
          marginBottom: 1,
          boxShadow: 3, // Elevation for cards
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
      >
        <Grid container spacing={2} direction="column" padding={2}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Districts</InputLabel>
              <Select
                value={selected.district || ''}
                onChange={(e) => handleSelect('district', e.target.value)}
                label="Districts"
                disabled={!districts.length}
                sx={{
                  backgroundColor: '#f5f5f5', // Light background for dropdown
                  borderRadius: '4px', // Slightly rounded corners for the dropdown
                }}
              >
                <MenuItem value="">Select District</MenuItem>
                {districts.map((district) => (
                  <MenuItem key={district.districtId} value={district.districtId}>
                    {district.districtName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      {/* Taluka Dropdown */}
      <Card
        sx={{
          marginBottom: 1,
          boxShadow: 3, // Elevation for cards
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
      >
        <Grid container spacing={2} direction="column" padding={2}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Talukas</InputLabel>
              <Select
                value={selected.taluka || ''}
                onChange={(e) => handleSelect('taluka', e.target.value)}
                label="Talukas"
                disabled={!selected.district || !talukas.length}
                sx={{
                  backgroundColor: '#f5f5f5', // Light background for dropdown
                  borderRadius: '4px',
                }}
              >
                <MenuItem value="">Select Taluka</MenuItem>
                {talukas.map((taluka) => (
                  <MenuItem key={taluka.talukaId} value={taluka.talukaId}>
                    {taluka.talukaName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>

      {/* Village Dropdown */}
      <Card
        sx={{
          marginBottom: 1,
          boxShadow: 3, // Elevation for cards
          backgroundColor: 'white',
          borderRadius: '8px',
        }}
      >
        <Grid container spacing={2} direction="column" padding={2}>
          <Grid item>
            <FormControl fullWidth>
              <InputLabel>Villages</InputLabel>
              <Select
                value={selected.village || ''}
                onChange={(e) => handleSelect('village', e.target.value)}
                label="Villages"
                disabled={!selected.taluka || !villages.length}
                sx={{
                  backgroundColor: '#f5f5f5', // Light background for dropdown
                  borderRadius: '4px',
                }}
              >
                <MenuItem value="">Select Village</MenuItem>
                {villages.map((village) => (
                  <MenuItem key={village.villageId} value={village.villageId}>
                    {village.villageName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Card>
    </aside>
  );
};

export default Sidebar;

import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Spinner, Alert, Row, Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const BeneficiaryMelave = () => {
  const [melave, setMelave] = useState([]);
  const [filteredMelave, setFilteredMelave] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTaluka, setSelectedTaluka] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Beneficiary Meet');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchMelave = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/beneficiarymelava/beneficiarymelava`);
        if (!response.ok) throw new Error('Failed to fetch beneficiary melava details');
        const data = await response.json();
        setMelave(data.data || []);
        setFilteredMelave(data.data || []);

        const uniqueDistricts = [...new Set(data.data.map((item) => item.district_name))];
        setDistricts(uniqueDistricts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchMelave();
  }, []);

  useEffect(() => {
    if (selectedDistrict) {
      const talukasInDistrict = [
        ...new Set(melave.filter((item) => item.district_name === selectedDistrict).map((item) => item.taluka_name)),
      ];
      setTalukas(talukasInDistrict);
    } else {
      setTalukas([]);
    }
  }, [selectedDistrict, melave]);

  useEffect(() => {
    let filteredData = melave;

    // Filter by district if selected
    if (selectedDistrict) {
      filteredData = filteredData.filter((item) => item.district_name === selectedDistrict);
    }

    // Filter by taluka if selected
    if (selectedTaluka) {
      filteredData = filteredData.filter((item) => item.taluka_name === selectedTaluka);
    }

    setFilteredMelave(filteredData);
  }, [selectedDistrict, selectedTaluka, melave]);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
    setSelectedTaluka('');  // Reset Taluka filter when district changes
  };

  const handleTalukaChange = (event) => {
    setSelectedTaluka(event.target.value);
  };

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);

    // Redirect based on the selected filter
    if (selected === '') {
      // If "All Reports" is selected, navigate to the main CapacityBuilding page
      navigate('/CapacityBuilding');
    } else if (selected === 'Workshop') {
      // If "Workshop" is selected, navigate to the Workshop page
      navigate('/Workshop');
    } else if (selected === 'Mason Training') {
      // If "Mason Training" is selected, navigate to the Mason Training page
      navigate('/masonTraining');
    } else if (selected === 'Beneficiary Meet') {
      // If "Beneficiary Meet" is selected, navigate to the Beneficiary Meet page
      navigate('/beneficiaryMelave');
    }
  };

  const exportToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Beneficiary Melava');
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setExportSuccess(true);
    setTimeout(() => setExportSuccess(false), 3000);  // Reset export success alert after 3 seconds
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Beneficiary Melava Data', 14, 16);
    doc.autoTable({
      head: [['District Name', 'Taluka Name', 'Village Name', 'Beneficiary Count', 'Melava Information', 'Melava Date']],
      body: filteredMelave.map((melava) => [
        melava.district_name,
        melava.taluka_name,
        melava.village_name,
        melava.beneficiary_count || 'N/A',
        melava.melava_mahiti || 'N/A',
        melava.melava_date || 'N/A',
      ]),
    });
    doc.save('BeneficiaryMelava.pdf');
  };

  const calculateTotalBeneficiaryCount = () => {
    let totalBeneficiaries = 0;
    filteredMelave.forEach((melava) => {
      if (melava.beneficiary_count) {
        totalBeneficiaries += parseInt(melava.beneficiary_count) || 0;
      }
    });
    return totalBeneficiaries;
  };

  const headers = [
    'Sr. No.',
    'जिल्हा',
    'तालुका',
    'ग्रामपंचायतचे नाव',
    'लाभार्थी संख्या',
    'मेळावा माहिती',
    'मेळावा तारीख',
  ];

  const renderTable = () => {
    if (loading) return <div style={{ textAlign: 'center', marginTop: '50px' }}><Spinner animation="border" /></div>;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    const totalBeneficiaries = calculateTotalBeneficiaryCount();

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx} style={{ textAlign: 'center' }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Total row before the first row */}
          <tr>
            <td colSpan="4" style={{ textAlign: 'left', fontWeight: 'bold' }}>Total</td>
            <td colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold' }}>{totalBeneficiaries}</td>
          </tr>
          {filteredMelave.map((melava, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td> {/* Serial number column */}
              <td>{melava.district_name}</td>
              <td>{melava.taluka_name}</td>
              <td>{melava.village_name}</td>
              <td>{melava.beneficiary_count || 'N/A'}</td>
              <td>{melava.melava_mahiti || 'N/A'}</td>
              <td>{melava.melava_date || 'N/A'}</td>
            </tr>
          ))}
          {/* Total row after the last row */}
          <tr>
            <td colSpan="4" style={{ textAlign: 'left', fontWeight: 'bold' }}>Total</td>
            <td colSpan="3" style={{ textAlign: 'left', fontWeight: 'bold' }}>{totalBeneficiaries}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col xs={12} md={2}>
          <div className="p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="">All Reports</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Mason Training">Mason Training</option>
                  <option value="Beneficiary Meet">Beneficiary Meet</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>Select District</Form.Label>
                <Form.Control as="select" value={selectedDistrict} onChange={handleDistrictChange}>
                  <option value="">All Districts</option>
                  {districts.map((district, idx) => (
                    <option key={idx} value={district}>{district}</option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Select Taluka</Form.Label>
                <Form.Control as="select" value={selectedTaluka} onChange={handleTalukaChange} disabled={!selectedDistrict}>
                  <option value="">All Talukas</option>
                  {talukas.map((taluka, idx) => (
                    <option key={idx} value={taluka}>{taluka}</option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col xs={12} md={10}>
          <div className="mb-2 d-flex">
            <h4 className="text-start mt-2 mb-2 col-lg-9">Beneficiary Melava Report</h4>
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => exportToExcel(filteredMelave, 'BeneficiaryMelava')} className="me-2">
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>Export to PDF</Button>
            </div>
          </div>
          {exportSuccess && <Alert variant="success">Export successful!</Alert>}
          {renderTable()}
        </Col>
      </Row>
    </div>
  );
};

export default BeneficiaryMelave;

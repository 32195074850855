import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Workshop = () => {
  const [workshops, setWorkshops] = useState([]);
  const [filteredWorkshops, setFilteredWorkshops] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTaluka, setSelectedTaluka] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('Workshop');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exportSuccess, setExportSuccess] = useState(false);

  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const fetchWorkshops = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiBaseUrl}/capacitybuilding/workshops`);
        if (!response.ok) throw new Error('Failed to fetch workshops');
        const data = await response.json();
        setWorkshops(data.data || []);
        setFilteredWorkshops(data.data || []);

        const uniqueDistricts = [...new Set(data.data.map((item) => item.district_name))];
        setDistricts(uniqueDistricts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkshops();
  }, [apiBaseUrl]);

  useEffect(() => {
    if (selectedDistrict) {
      const talukasInDistrict = [
        ...new Set(workshops.filter((item) => item.district_name === selectedDistrict).map((item) => item.taluka_name)),
      ];
      setTalukas(talukasInDistrict);
    } else {
      setTalukas([]);
    }
  }, [selectedDistrict, workshops]);

  useEffect(() => {
    let filteredData = workshops;
    if (selectedDistrict) {
      filteredData = filteredData.filter((item) => item.district_name === selectedDistrict);
    }
    if (selectedTaluka) {
      filteredData = filteredData.filter((item) => item.taluka_name === selectedTaluka);
    }
    setFilteredWorkshops(filteredData);
  }, [selectedDistrict, selectedTaluka, workshops, selectedFilter]);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
    setSelectedTaluka('');
  };

  const handleTalukaChange = (event) => {
    setSelectedTaluka(event.target.value);
  };

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
  
    // Redirect based on the selected filter
    if (selected === '') {
      // If "All Reports" is selected, navigate to the main CapacityBuilding page
      navigate('/CapacityBuilding');
    } else if (selected === 'Workshop') {
      // If "Workshop" is selected, navigate to the Workshop page
      navigate('/Workshop');
    } else if (selected === 'Mason Training') {
      // If "Mason Training" is selected, navigate to the Mason Training page
      navigate('/masonTraining');
    } else if (selected === 'Beneficiary Meet') {
      // If "Beneficiary Meet" is selected, navigate to the Beneficiary Meet page
      navigate('/beneficiaryMelave');
    }
  };
  
  const exportToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Workshops');
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setExportSuccess(true);
    setTimeout(() => setExportSuccess(false), 3000);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Workshop Report", 14, 16);
    doc.autoTable({
      head: [['Sr.No', 'Abhiyan Name', 'District Name', 'Taluka Name', 'Village Name', 'Workshop Count']],
      body: filteredWorkshops.map((workshop, index) => [
        index + 1,
        workshop.abhiyan_name,
        workshop.district_name,
        workshop.taluka_name,
        workshop.village_name,
        workshop.karyashala_sankhya || 'N/A'
      ]),
    });
    doc.save('Workshops.pdf');
  };

  const calculateTotals = () => {
    let totalWorkshops = 0;
    filteredWorkshops.forEach(workshop => {
      if (workshop.karyashala_sankhya) {
        totalWorkshops += parseInt(workshop.karyashala_sankhya);
      }
    });
    return totalWorkshops;
  };

  const headers = ['Sr.No', 'अभियान', 'जिल्हा', 'तालुका', 'ग्रामपंचायतचे नाव', 'जिल्ह्यात झालेल्या एकूण कार्यशाळेची संख्या'];

  const renderTable = () => {
    if (loading) return <div style={{ textAlign: 'center', marginTop: '50px' }}><Spinner animation="border" /></div>;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx} style={{ textAlign: 'center' }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={5} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{calculateTotals()}</strong></td>
          </tr>
          {filteredWorkshops.map((workshop, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{workshop.abhiyan_name}</td>
              <td>{workshop.district_name}</td>
              <td>{workshop.taluka_name}</td>
              <td>{workshop.village_name}</td>
              <td>{workshop.karyashala_sankhya || 'N/A'}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={5} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{calculateTotals()}</strong></td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col xs={12} md={2}>
          <div className="p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="">All Reports</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Mason Training">Mason Training</option>
                  <option value="Beneficiary Meet">Beneficiary Meet</option>
                  
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="districtSelect" className="mb3">
                <Form.Label>Select District</Form.Label>
                <Form.Control as="select" value={selectedDistrict} onChange={handleDistrictChange}>
                  <option value="">All Districts</option>
                  {districts.map((district, idx) => (
                    <option key={idx} value={district}>
                      {district}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Select Taluka</Form.Label>
                <Form.Control as="select" value={selectedTaluka} onChange={handleTalukaChange} disabled={!selectedDistrict}>
                  <option value="">All Talukas</option>
                  {talukas.map((taluka, idx) => (
                    <option key={idx} value={taluka}>
                      {taluka}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>
        <Col xs={12} md={10}>
          <div className="mb-2 d-flex">
            <h4 className="text-start mt-2 mb-2 col-lg-9">Workshops Report</h4>
            <div className='d-flex justify-content-end'>
              <Button variant="primary" onClick={() => exportToExcel(filteredWorkshops, 'Workshops')} className="me-2">
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>
                Export to PDF
              </Button>
            </div>
          </div>
          {exportSuccess && <Alert variant="success">Export successful!</Alert>}
          {renderTable()}
        </Col>
      </Row>
    </div>
  );
};

export default Workshop;

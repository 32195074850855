import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Spinner, Alert, Row, Col } from 'react-bootstrap';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useNavigate } from 'react-router-dom';

const MassonTraining = () => {
  const [trainings, setTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [selectedTaluka, setSelectedTaluka] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState('Mason Training');

  const navigate = useNavigate();

  // Fetch all mason trainings at component mount
  useEffect(() => {
    const fetchTrainings = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/massontraining/masontraining`);
       if (!response.ok) throw new Error('Failed to fetch mason trainings');
        const data = await response.json();
        setTrainings(data.data || []);
        setFilteredTrainings(data.data || []);

        // Extract unique districts
        const uniqueDistricts = [...new Set(data.data.map((item) => item.district_name))];
        setDistricts(uniqueDistricts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTrainings();
  }, []);

  // Update talukas based on the selected district
  useEffect(() => {
    if (selectedDistrict) {
      const talukasInDistrict = [
        ...new Set(trainings.filter((item) => item.district_name === selectedDistrict).map((item) => item.taluka_name)),
      ];
      setTalukas(talukasInDistrict);
    } else {
      setTalukas([]);
    }
  }, [selectedDistrict, trainings]);

  // Filter mason trainings dynamically based on district and taluka
  useEffect(() => {
    let filteredData = trainings;
    if (selectedDistrict) {
      filteredData = filteredData.filter((item) => item.district_name === selectedDistrict);
    }
    if (selectedTaluka) {
      filteredData = filteredData.filter((item) => item.taluka_name === selectedTaluka);
    }
    setFilteredTrainings(filteredData);
  }, [selectedDistrict, selectedTaluka, trainings]);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
    setSelectedTaluka(''); // Reset taluka when district changes
  };

  const handleTalukaChange = (event) => {
    setSelectedTaluka(event.target.value);
  };

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
  
    // Redirect based on the selected filter
    if (selected === '') {
      // If "All Reports" is selected, navigate to the main CapacityBuilding page
      navigate('/CapacityBuilding');
    } else if (selected === 'Workshop') {
      // If "Workshop" is selected, navigate to the Workshop page
      navigate('/Workshop');
    } else if (selected === 'Mason Training') {
      // If "Mason Training" is selected, navigate to the Mason Training page
      navigate('/masonTraining');
    } else if (selected === 'Beneficiary Meet') {
      // If "Beneficiary Meet" is selected, navigate to the Beneficiary Meet page
      navigate('/beneficiaryMelave');
    }
  };
    
  // Export to Excel
  const exportToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Mason Trainings');
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setExportSuccess(true);
    setTimeout(() => setExportSuccess(false), 3000); // Reset export success message after 3 seconds
  };

  // Export to PDF
  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text('Mason Training Data', 14, 16);
    doc.autoTable({
      head: [['District Name', 'Taluka Name', 'Village Name', 'Total Trainings', 'Completed Trainings', 'Assessment']],
      body: filteredTrainings.map((training) => [
        training.district_name,
        training.taluka_name,
        training.village_name,
        training.yekun_prashikshan_sankya || 'N/A',
        training.prashikshan_purn_zalelya_gavandi_sankya || 'N/A',
        training.assessment || 'N/A',
      ]),
    });
    doc.save('MasonTrainings.pdf');
  };

  // Calculate total counts for each column
  const calculateTotals = () => {
    let totalTrainings = 0;
    let completedTrainings = 0;
    filteredTrainings.forEach((training) => {
      if (training.yekun_prashikshan_sankya) {
        totalTrainings += parseInt(training.yekun_prashikshan_sankya);
      }
      if (training.prashikshan_purn_zalelya_gavandi_sankya) {
        completedTrainings += parseInt(training.prashikshan_purn_zalelya_gavandi_sankya);
      }
    });
    return { totalTrainings, completedTrainings };
  };

  // Table headers
  const headers = [
    'जिल्हा',
    'तालुका',
    'ग्रामपंचायतचे नाव',
    'एकूण प्रशिक्षण संख्या',
    'पूर्ण प्रशिक्षण संख्या',
    'मूल्यांकन'
  ];

  // Render table
  const renderTable = () => {
    if (loading) return <div style={{ textAlign: 'center', marginTop: '50px' }}><Spinner animation="border" /></div>;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    // Calculate totals for trainings and completed trainings
    const { totalTrainings, completedTrainings } = calculateTotals();

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {/* Add Sr. No header */}
            <th style={{ textAlign: 'center' }}>Sr. No</th>
            {headers.map((header, idx) => (
              <th key={idx} style={{ textAlign: 'center' }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {/* Total Row Before First Row */}
          <tr>
            <td colSpan={4} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td className="text-left fw-bold text-dark">{totalTrainings}</td>
            <td className="text-left fw-bold text-dark">{completedTrainings}</td>
            <td></td> {/* Empty cell for 'Assessment' */}
          </tr>

          {/* Regular Training Rows with Sr.No */}
          {filteredTrainings.map((training, idx) => (
            <tr key={idx}>
              {/* Add Sr. No for each row */}
              <td style={{ textAlign: 'center' }}>{idx + 1}</td>
              <td>{training.district_name}</td>
              <td>{training.taluka_name}</td>
              <td>{training.village_name}</td>
              <td>{training.yekun_prashikshan_sankya || 'N/A'}</td>
              <td>{training.prashikshan_purn_zalelya_gavandi_sankya || 'N/A'}</td>
              <td>{training.assessment || 'N/A'}</td>
            </tr>
          ))}

          {/* Total Row After Last Row */}
          <tr>
            <td colSpan={4} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td className="text-left fw-bold text-dark">{totalTrainings}</td>
           <td className="text-left fw-bold text-dark">{completedTrainings}</td>
            <td></td> {/* Empty cell for 'Assessment' */}
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <div className="container mt-4">
      <Row>
        {/* Sidebar (Filters) */}
        <Col xs={12} md={2}>
          <div className="p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h5>Filters</h5>
            <Form>
              {/* Filter Selector */}
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="">All Reports</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Mason Training">Mason Training</option>
                  <option value="Beneficiary Meet">Beneficiary Meet</option>
                  
                </Form.Control>
              </Form.Group>
              {/* District Selector */}
              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>Select District</Form.Label>
                <Form.Control as="select" value={selectedDistrict} onChange={handleDistrictChange}>
                  <option value="">All Districts</option>
                  {districts.map((district, idx) => (
                    <option key={idx} value={district}>
                      {district}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              {/* Taluka Selector */}
              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Select Taluka</Form.Label>
                <Form.Control as="select" value={selectedTaluka} onChange={handleTalukaChange} disabled={!selectedDistrict}>
                  <option value="">All Talukas</option>
                  {talukas.map((taluka, idx) => (
                    <option key={idx} value={taluka}>
                      {taluka}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        {/* Mason Training Table */}
        <Col xs={12} md={10}>
          <div className="mb-2 d-flex">
            <h4 className="text-start mt-2 mb-2 col-lg-9">Mason Training Report</h4>
            <div className="d-flex justify-content-end">
              <Button variant="primary" onClick={() => exportToExcel(filteredTrainings, 'MasonTrainings')} className="me-2">
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>Export to PDF</Button>
            </div>
          </div>

          {renderTable()}

          {exportSuccess && (
            <Alert variant="success" className="mt-3">
              Data exported successfully!
            </Alert>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default MassonTraining;

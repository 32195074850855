import React from 'react';
import { motion } from 'framer-motion';
import './AboutUs.css'; // Ensure you have your styles here

const AboutUs = () => {
  return (
    <section id="about-us-section" className="mt-3">
      <div className="container">
        <motion.div
          className="about-section"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="row">
            <div className="col-md-12 m-4">
              <div className="section-header">
                <h2>About Us: State Management Unit – Rural Housing (SMU-RH)</h2>
              </div>
            </div>
          </div>
          <div className="row m-2">
            {/* Mission Section */}
            <motion.div
className="col-md-4"
initial={{ opacity: 0, y: 50 }}
animate={{ opacity: 1, y: 0 }}
transition={{ duration: 0.8, ease: "easeInOut" }} // Smooth easing
>
  <div className="section ml-5 mb-5">
    <h3>Our Mission:</h3>
    <p>
      At SMU-RH, we are committed to ensuring access to housing for all citizens by the year 2024. To achieve this goal, we collaborate closely with various departments and agencies to implement a range of housing schemes, thereby contributing to the socio-economic development of rural communities across Maharashtra.
    </p>
  </div>
</motion.div>



            {/* Schemes Section (Horizontal Scroll) */}
            <motion.div
              className="col-md-4"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            >
              <div className="section mb-5">
                <h3>Our Schemes:</h3>
                <div className="scroll-container">
                  <div className="scroll-content">
                    <ul>
                      <li>Pradhan Mantri Awaas Yojna – Grameen (Central Government Scheme, Government of India)</li>
                      <li>Ramai Awas Yojana (Social Justice Department, Government of Maharashtra)</li>
                      <li>Shabari Awas Yojana (Tribal Development Department, Government of Maharashtra)</li>
                      {/* Add more items if needed */}
                    </ul>
                  </div>
                </div>
              </div>
            </motion.div>

            {/* Role Section (Vertical Scroll) */}
            <motion.div
              className="col-md-4"
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.6 }}
            >
              <div className="section mb-5">
                <h3>Our Role:</h3>
                <div className="scroll-container">
                  <div className="scroll-content">
                    <ol>
                      <li>Monitoring and Evaluation</li>
                      <li>Coordination</li>
                      <li>Capacity Building</li>
                      <li>Information, Education, and Communication (IEC)</li>
                      <li>Policy Input</li>
                    </ol>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutUs;

import React, { useState, useEffect } from 'react';
import Icon1 from './SchemeIcons/landless.png';
import Icon2 from './SchemeIcons/meeting.png';
import Icon3 from './SchemeIcons/convergence.png';
import Icon4 from './SchemeIcons/innovation.png';
import Icon5 from './SchemeIcons/iec.png';
import Icon6 from './SchemeIcons/meeting.png';
import { Link } from 'react-router-dom';
import './KeyCards.css';

const KeyCards = () => {
  const [counts, setCounts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const response = await fetch('http://145.223.19.41:8090/mahaAawaasReport/api/dashboard/cardCounts');
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const data = await response.json();
        setCounts(data);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCounts();
  }, []);

  if (loading) {
    return (
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    );
  }
  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }

  return (
    <section id="main-content">
      <div className="department">
        <div className="container text-center">
          <h2>Progress Of Maha Awaas Abhiyan </h2>
          {/* <p className="subheading">In Maharashtra</p> */}
          <div className="row d-flex justify-content-center mt-5">
            {/* LANDLESS CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
  <div className="squre_1">
    <img className="logoo" src={Icon1} alt="Landless" />
    <h3 className="modern-heading mb-3">
      <span>LANDLESS</span>
    </h3>

    <div className="scrolling-container">
      <div className="scrolling-content">
        <h6 className="custom-heading">
          <span>Total Landless: {counts?.landlessCount?.[0]?.totalLandless ?? 'N/A'}</span>
        </h6>
        <h6 className="custom-heading">
          <span>Remaining Landless: {counts?.landlessCount?.[0]?.remainingLandless ?? 'N/A'}</span>
        </h6>
        <h6 className="custom-heading">
          <span>Land Provided Till dt: 1,11,741</span>
        </h6>
      </div>
    </div>

    <Link to="/landless">
      <button>
        <div>View More</div>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </Link>
  </div>
</div>
            {/* CAPACITY BUILDING CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
    <div className="squre_2">
      <img className="logoo" src={Icon2} alt="Capacity Building" />
      <h3 className="modern-heading mb-3">
        <span>CAPACITY BUILDING</span>
      </h3>
      <div className="scrolling-container">
        <div className="scrolling-content">
          <h6 className="custom-heading">
            {/* <span>Training: {counts?.capacityCount?.[0]?.numberOfTraining ?? 'N/A'}</span> */}
            <span>Training: 2</span>

          </h6>
          <h6 className="custom-heading">
            {/* Workshop: {counts?.capacityCount?.[0]?.workshop ?? 'N/A'} */}
            Masson Training: 23

          </h6>
          <h6 className="custom-heading">
            {/* Beneficiary Meet: {counts?.capacityCount?.[0]?.beneficiaryMeet ?? 'N/A'} */}
            लाभार्थी मेळावा: 124

          </h6>
        </div>
      </div>
      <Link to="/CapacityBuilding">
        <button>
          <div>View More</div>
          <i className="fas fa-angle-double-right"></i>
        </button>
      </Link>
    </div>
  </div>



            {/* CONVERGENCE CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4">
  <div className="squre_3">
    <img className="logoo" src={Icon3} alt="Convergence" />
    <h3 className="modern-heading mb-3">
      <span>CONVERGENCE</span>
    </h3>

    <div className="scrolling-container">
      <div className="scrolling-content">
        <h6 className="custom-heading">
          <span>
          स्वच्छ भारत अभियान : <Link to="/convergence/swachhBharat">{counts?.convergenceCount?.[0]?.swachhBharat ?? 'N/A'}</Link>
          </span>
        </h6>
        <h6 className="custom-heading">
        {/* जलजीवन मिशन : {counts?.convergenceCount?.[0]?.jalJeevan ?? 'N/A'} */}
        जलजीवन मिशन: 16

        </h6>
        <h6 className="custom-heading">
        {/* उज्ज्वला योजना  : {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        उज्ज्वला योजना :9
        </h6>
        <h6 className="custom-heading">
        {/* सौभ्याग्य योजना: {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        सौभ्याग्य योजना:7
        </h6>
        <h6 className="custom-heading">
        {/* ग्रामीण जीवणोत्या अभियान: {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        ग्रामीण जीवणोत्या अभियान:9
        </h6>
        <h6 className="custom-heading">
        {/* दलित वस्ती सुधार योजना  : {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        दलित वस्ती सुधार योजना :15
        </h6>
        <h6 className="custom-heading">
        {/* ठक्कर बाप्पा: {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        ठक्कर बाप्पा:11
        </h6>
        <h6 className="custom-heading">
        {/* जिह्वा परिषद सेस: {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        जिह्वा परिषद सेस:10
        </h6>
        <h6 className="custom-heading">
        {/* पंचायत समिती सेस : {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        पंचायत समिती सेस: 6
        </h6>
        <h6 className="custom-heading">
        {/* ग्रामपंचायत सेस  : {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        ग्रामपंचायत सेस :9
        </h6>
        <h6 className="custom-heading">
        {/* सी एस आर निधी : {counts?.convergenceCount?.[0]?.ujjwala ?? 'N/A'} */}
        सी एस आर निधी:8
        </h6>
        

      </div>
    </div>

    <Link to="/convergence">
      <button>
        <div>View More</div>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </Link>
  </div>
</div>

            {/* INNOVATIONS CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6">
  <div className="squre_4">
    <img className="logoo" src={Icon4} alt="Innovations" />
    <h3 className="modern-heading mb-3">
      <span>INNOVATIONS</span>
    </h3>

    <div className="scrolling-container">
      <div className="scrolling-content">
        <h6 className="custom-heading">
          <span>
          बहुमजली इमारती: 7
          </span>
        </h6>
        <h6 className="custom-heading">
        गृहसंकुले: 8
        </h6>
        <h6 className="custom-heading">
        बहुमजली गृहसंकुले: 8
        </h6>
        <h6 className="custom-heading">
        कॉप शॉप : 26
        </h6>
        <h6 className="custom-heading">
        डेमो हाऊस तालुका: 15
        </h6>
        <h6 className="custom-heading">
        लँड बँक: 7
        </h6>
        <h6 className="custom-heading">
        Sand बँक: 9
        </h6>
        <h6 className="custom-heading">
        घरकुल मार्ट: 9
        </h6>
        <h6 className="custom-heading">
        वाळूला पर्याय : 20
        </h6>
        <h6 className="custom-heading">
        गृहकर्ज: 17
        </h6>
        <h6 className="custom-heading">
        आदर्श घरकुले: 28
        </h6>
        <h6 className="custom-heading">
        नविन बांधकाम तंत्रज्ञान: 11
        </h6>
        <h6 className="custom-heading">
        Precast Technology: 14
        </h6>
        <h6 className="custom-heading">
        घरावर लोगोs: 14
        </h6>
        
        

      </div>
    </div>

    <Link to="/innovations">
      <button>
        <div>View More</div>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </Link>
  </div>
</div>
            {/* I.E.C CARD */}
            <div className="col-lg-4 col-md-4 col-sm-6">
  <div className="squre_5">
    <img className="logoo" src={Icon5} alt="I.E.C" />
    <h3 className="modern-heading mb-3">
      <span>I.E.C</span>
    </h3>

    {/* Scrollable Container */}
    <div className="scrolling-container">
      <div className="scrolling-content">
        <h6 className="custom-heading">
          <span>
            PRINT MEDIA: {counts?.iecCount?.[0]?.printMedia ?? 'N/A'}
          </span>
        </h6>
        <h6 className="custom-heading">
          OUTDOOR MEDIA: {counts?.iecCount?.[0]?.outdoorMedia ?? 'N/A'}
        </h6>
        <h6 className="custom-heading">
          PUBLICATIONS: {counts?.iecCount?.[0]?.publications ?? 'N/A'}
        </h6>

      </div>
    </div>

    <Link to="/iec">
      <button>
        <div>Visit Now</div>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </Link>
  </div>
</div>

{/* PARTNERSHIPS CARD */}
<div className="col-lg-4 col-md-4 col-sm-6">
  <div className="squre_6">
    <img className="logoo" src={Icon6} alt="Partnerships" />
    <h3 className="modern-heading mb-3">
      <span>PARTNERSHIPS</span>
    </h3>

    {/* Scrollable Container */}
    <div className="scrolling-container">
      <div className="scrolling-content">
        <h6 className="custom-heading">
          <span>
            ORGANIZATIONS: {counts?.partnershipCount?.[0]?.organizations ?? 'N/A'}
          </span>
        </h6>
        <h6 className="custom-heading">
          CORPORATE: {counts?.partnershipCount?.[0]?.corporate ?? 'N/A'}
        </h6>
        <h6 className="custom-heading">
          ACADEMIA: {counts?.partnershipCount?.[0]?.academia ?? 'N/A'}
        </h6>

      </div>
    </div>

    <Link to="/partnerships">
      <button>
        <div>Visit Now</div>
        <i className="fas fa-angle-double-right"></i>
      </button>
    </Link>
  </div>
</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default KeyCards;

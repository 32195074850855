import React, { useEffect, useState, useCallback } from 'react';
import Sidebar from './Sidebar';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography, Grid } from '@mui/material';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { pdf } from '@react-pdf/renderer';
import { Document, Page, View, Text, StyleSheet } from '@react-pdf/renderer';
import './style.css';

const Landless = () => {
  const [districts, setDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [villages, setVillages] = useState([]);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [selected, setSelected] = useState({ district: null, taluka: null, village: null });
  const [currentView, setCurrentView] = useState('districts');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const apiBaseUrl = process.env.REACT_APP_API_URL;

  // General fetchData function for API calls
  const fetchData = useCallback(async (endpoint, setter) => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(`${apiBaseUrl}${endpoint}`);
      if (!response.ok) throw new Error('Failed to fetch data');
      const data = await response.json();
      setter(data.data || []);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  }, [apiBaseUrl]);

  useEffect(() => {
    fetchData('/landless/districts', setDistricts);
  }, [fetchData]);

  useEffect(() => {
    if (selected.district) {
      fetchData(`/landless/districts/${selected.district}/talukas`, setTalukas);
      setSelected((prevState) => ({ ...prevState, taluka: null, village: null }));
    }
  }, [selected.district, fetchData]);

  useEffect(() => {
    if (selected.taluka) {
      fetchData(`/landless/districts/${selected.district}/talukas/${selected.taluka}/villages`, setVillages);
      setSelected((prevState) => ({ ...prevState, village: null }));
    }
  }, [selected.taluka, selected.district, fetchData]);

  useEffect(() => {
    if (selected.village) {
      fetchData(`/landless/districts/${selected.district}/talukas/${selected.taluka}/villages/${selected.village}/beneficiaries`, setBeneficiaries);
    }
  }, [selected.village, selected.district, selected.taluka, fetchData]);

  const handleSelect = (type, value) => {
    setSelected((prev) => ({ ...prev, [type]: value }));
    if (type === 'district') {
      setCurrentView('talukas');
    } else if (type === 'taluka') {
      setCurrentView('villages');
    } else if (type === 'village') {
      setCurrentView('beneficiaries');
    }
  };

  // Export to Excel
  const exportToExcel = (data, headers, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  // Export to PDF
  const exportToPDF = (data, headers, filename) => {
    const doc = pdf(<MyDocument data={data} headers={headers} />);
    doc.toBlob().then((blob) => saveAs(blob, `${filename}.pdf`));
  };

  // Calculate totals
  const calculateTotals = (data, headers) => {
    const totals = new Array(headers.length - 2).fill(0); // Initialize totals for numeric columns

    // Iterate over the rows to calculate the totals for each numeric column
    data.forEach((row) => {
      headers.slice(2).forEach((header, index) => {
        const value = row[header];
        const numericValue = parseFloat(value); // Convert the value to a number
        if (!isNaN(numericValue)) {
          totals[index] += numericValue; // Accumulate the sum for this column
        }
      });
    });

    return totals;
  };

  const renderTable = () => {
    const headers = {
      districts: ['Sr.No', 'अ.न.', 'जिल्हा', 'एकुण भूमिहीन लाभार्थी', 'पंडीत दीन दयाळ उपाध्याय घरकुल जागा खरेदी अर्थ सहाय्य योजना', 'शासकीय जागा विनामूल्य उपलब्ध करण्याची योजना', 'अतिक्रमणे नियमानुकूल करणे योजना', 'इतर', 'आज अखेर जागा उपलब्ध', 'उर्वरित भूमिहीन लाभार्थी'],
      talukas: ['Sr.No', 'अ.न.', 'Taluka Name', 'एकुण भूमिहीन लाभार्थी', 'पंडीत दीन दयाळ उपाध्याय घरकुल जागा खरेदी अर्थ सहाय्य योजना', 'शासकीय जागा विनामूल्य उपलब्ध करण्याची योजना', 'अतिक्रमणे नियमानुकूल करणे योजना', 'इतर', 'आज अखेर जागा उपलब्ध', 'उर्वरित भूमिहीन लाभार्थी'],
      villages: ['Sr.No', 'अ.न.', 'Village Name', 'एकुण भूमिहीन लाभार्थी', 'पंडीत दीन दयाळ उपाध्याय घरकुल जागा खरेदी अर्थ सहाय्य योजना', 'शासकीय जागा विनामूल्य उपलब्ध करण्याची योजना', 'अतिक्रमणे नियमानुकूल करणे योजना', 'इतर', 'आज अखेर जागा उपलब्ध', 'उर्वरित भूमिहीन लाभार्थी'],
      beneficiaries: ['Sr.No', 'अ.न.', 'Village Name', 'Beneficiary Name', 'Registration Number', 'Status'],
    };

    const data = {
      districts: districts,
      talukas: talukas,
      villages: villages,
      beneficiaries: beneficiaries,
    };

    const currentData = data[currentView];
    const currentHeaders = headers[currentView];

    const totals = calculateTotals(currentData, currentHeaders);

    if (loading) return <div className="loading-spinner">Loading...</div>;
    if (error) return <p className="error-message">Error: {error}</p>;

    return (
      <TableContainer component={Paper}>
        <Typography variant="h5" align="left" gutterBottom style={{ margin: '20px' }}>
          प्रधानमंत्री आवास योजना ग्रामीण मधील भूमिहीन लाभार्थ्यांना जागा उपलब्ध करून देणे
        </Typography>

        <Grid container justifyContent="flex-end" spacing={2} style={{ marginBottom: '20px' }}>
          <Grid item>
            <Button variant="contained" color="primary" onClick={() => exportToExcel(currentData, currentHeaders, currentView)}>
              Export to Excel
            </Button>
          </Grid>
          <Grid item>
            <Button variant="contained" color="secondary" onClick={() => exportToPDF(currentData, currentHeaders, currentView)}>
              Export to PDF
            </Button>
          </Grid>
        </Grid>

        <Table className="table table-striped">
          <TableHead>
            <TableRow>
              {currentHeaders.slice(1).map((header, index) => (
                <TableCell key={index} align="center" style={{ fontWeight: 'bold' }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {currentData.map((row, rowIndex) => (
              <TableRow key={rowIndex}>
                <TableCell align="center">{rowIndex + 1}</TableCell> {/* Serial number column */}
                {Object.entries(row)
                  .slice(1) // Skip the first column (Sr.No)
                  .map(([key, cell], cellIndex) => (
                    <TableCell key={cellIndex} align="center">
                      {cell}
                    </TableCell>
                  ))}
              </TableRow>
            ))}

            {/* Total Row */}
            {/* <TableRow>
              <TableCell align="center" colSpan={1}>Total</TableCell>
              {totals.map((total, index) => (
                <TableCell key={index} align="center">{total.toFixed(2)}</TableCell>
              ))}
            </TableRow> */}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-lg-2 sidebar-container">
          <Sidebar
            districts={districts}
            talukas={talukas}
            villages={villages}
            handleSelect={handleSelect}
            currentView={currentView}
            selected={selected}
          />
        </div>
        <div className="col-lg-10 px-10 main-content">
          {renderTable()}
        </div>
      </div>
    </div>
  );
};

// PDF document component for exporting PDF
const MyDocument = ({ data, headers }) => (
  <Document>
    <Page style={styles.page}>
      <View style={styles.section}>
        <Text style={styles.header}>प्रधानमंत्री आवास योजना ग्रामीण मधील भूमिहीन लाभार्थ्यांना जागा उपलब्ध करून देणे</Text>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            {headers.slice(1).map((header, index) => (
              <Text key={index} style={styles.tableCell}>{header}</Text>
            ))}
          </View>
          {data.map((row, rowIndex) => (
            <View key={rowIndex} style={styles.tableRow}>
              {Object.entries(row)
                .slice(1)
                .map(([key, value], cellIndex) => (
                  <Text key={cellIndex} style={styles.tableCell}>{value}</Text>
                ))}
            </View>
          ))}
        </View>
      </View>
    </Page>
  </Document>
);

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    padding: 20,
  },
  header: {
    fontSize: 18,
    textAlign: 'center',
    marginBottom: 20,
    fontWeight: 'bold',
  },
  table: {
    display: 'table',
    width: '100%',
    marginTop: 10,
  },
  tableRow: {
    display: 'table-row',
  },
  tableCell: {
    padding: 5,
    borderWidth: 1,
    borderColor: 'black',
    textAlign: 'center',
  },
});

export default Landless;

import React from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom'; // Use Routes instead of Switch
import TopBar from './Components/Topbar/Topbar';
import TopBelow from './Components/Topbar/TopBelow';
import { ThemeProvider } from './Screens/ThemeContext'; // Import ThemeProvider
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Landless from './Components/DepartmentalStatastics/Landless/Landless';
import CapacityBuilding from './Components/DepartmentalStatastics/CapacityBuilding/CapacityBuilding';
import Workshop from './Components/DepartmentalStatastics/CapacityBuilding/Workshop';
import MassonTraining from './Components/DepartmentalStatastics/CapacityBuilding/MassonTraining';
import BeneficiaryMelave from './Components/DepartmentalStatastics/CapacityBuilding/BeneficiaryMelave';
import Convergence from './Components/DepartmentalStatastics/Convergence/Convergence';
import UjjwalaYojana from './Components/DepartmentalStatastics/Convergence/UjjwalaYojana';
import SwachhBharatAbhiyan from './Components/DepartmentalStatastics/Convergence/SwachhBharatAbhiyan';
import JaljivanMission from './Components/DepartmentalStatastics/Convergence/JaljivanMission';
import DalitVastiSudharYojana from './Components/DepartmentalStatastics/Convergence/DalitVastiSudharYojana'
import GraminJivannotyaAbhiyan from './Components/DepartmentalStatastics/Convergence/GraminJivannotyaAbhiyan'
import GrampanchayatSes from './Components/DepartmentalStatastics/Convergence/GrampanchayatSes'
import JilhaParishadSes from './Components/DepartmentalStatastics/Convergence/JilhaParishadSes'
import PanchayatSamitiSes from './Components/DepartmentalStatastics/Convergence/PanchayatSamitiSes'
import SaubhagyaYojana from './Components/DepartmentalStatastics/Convergence/SaubhagyaYojana'
import ThakkarBappaYojana from './Components/DepartmentalStatastics/Convergence/ThakkarBappaYojana'
import Innovations from './Components/DepartmentalStatastics/Innovations/Innovations';
import InnovationsOne from './Components/DepartmentalStatastics/Innovations/InnovationOne';
import InnovationsTwo from './Components/DepartmentalStatastics/Innovations/InnovationsTwo';

import IEC from './Components/DepartmentalStatastics/IEC/IEC';
import Partnerships from './Components/DepartmentalStatastics/Partnership/Partnerships';
import Footer from './Components/Footer/Footer';
import Gruhsankule from './Components/DepartmentalStatastics/Innovations/Gruhsankule';
import BahumajliImarti from './Components/DepartmentalStatastics/Innovations/Bahumajali';
import BahumajaliGruhsankule from './Components/DepartmentalStatastics/Innovations/BahumajaliGruhsankule';
import AdarshGharkule from './Components/DepartmentalStatastics/Innovations/AdarshGharkule';
import CropShop from './Components/DepartmentalStatastics/Innovations/CropShop';
import DemoHouseTaluka from './Components/DepartmentalStatastics/Innovations/DemoHouseTaluka';
import GharavarLogo from './Components/DepartmentalStatastics/Innovations/GharavarLogo';
import GharkulMart from './Components/DepartmentalStatastics/Innovations/GharkulMart';
import GruhKarj from './Components/DepartmentalStatastics/Innovations/GruhKarja';
import LandBank from './Components/DepartmentalStatastics/Innovations/LandBank';
import NavinBandhkam from './Components/DepartmentalStatastics/Innovations/NavinBandhkam';
import PrecastTech from './Components/DepartmentalStatastics/Innovations/PrecastTech';
import SandBank from './Components/DepartmentalStatastics/Innovations/SandBank';
import ValulaParyay from './Components/DepartmentalStatastics/Innovations/ValulaParyay';
import AboutUs from './Components/Home/AboutUs/AboutUs';
import ContactUs from './Components/Home/AboutUs/ContactUs';

function App() {
  return (
    <ThemeProvider>
      <BrowserRouter>
        <div>
          <TopBar />
          <TopBelow />
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/landless" element={<Landless />} />
            <Route path="/CapacityBuilding" element={<CapacityBuilding />} />
            <Route path="/convergence" element={<Convergence />} />
            <Route path="/innovations" element={<Innovations />} />
            <Route path="/innovationsOne" element={<InnovationsOne />} />
            <Route path="/innovationsTwo" element={<InnovationsTwo />} />

            <Route path="/iec" element={<IEC />} />
            <Route path="/partnerships" element={<Partnerships />} />
            <Route path="/workshop" element={<Workshop />} />
            <Route path="/masonTraining" element={<MassonTraining />} />
            <Route path="/beneficiaryMelave" element={<BeneficiaryMelave />} />
            <Route path="/UjjwalaYojana" element={<UjjwalaYojana />} />
            <Route path="/SwachhBharatAbhiyan" element={<SwachhBharatAbhiyan />} />
            <Route path="/JaljivanMission" element={<JaljivanMission />} />
            <Route path="/DalitVastiSudharYojana" element={<DalitVastiSudharYojana />} />
            <Route path="/GraminJivannotyaAbhiyan" element={<GraminJivannotyaAbhiyan />} />
            <Route path="/GrampanchayatSes" element={<GrampanchayatSes />} />
            <Route path="/JilhaParishadSes" element={<JilhaParishadSes />} />
            <Route path="/PanchayatSamitiSes" element={<PanchayatSamitiSes />} />
            <Route path="/SaubhagyaYojana" element={<SaubhagyaYojana />} />
            <Route path="/ThakkarBappaYojana" element={<ThakkarBappaYojana />} />
            <Route path="/Bahumajali" element={<BahumajliImarti />} />
            <Route path="/GruhSankule" element={<Gruhsankule />} />
            <Route path="/DalitVastiSudharYojana" element={<DalitVastiSudharYojana />} />
            <Route path="/AdarshGharkule" element={<AdarshGharkule />} />
            <Route path="/BahumajaliGruhsankule" element={<BahumajaliGruhsankule />} />
            <Route path="/CropShop" element={<CropShop />} />
            <Route path="/DemoHouseTaluka" element={<DemoHouseTaluka />} />
            <Route path="/GharavarLogo" element={<GharavarLogo />} />
            <Route path="/GharkulMart" element={<GharkulMart />} />
            <Route path="/GruhKarj" element={<GruhKarj />} />
            <Route path="/LandBank" element={<LandBank />} />
            <Route path="/NavinBandhkam" element={<NavinBandhkam />} />
            <Route path="/PrecastTech" element={<PrecastTech />} />
            <Route path="/SandBank" element={<SandBank />} />
            <Route path="/ValulaParyay" element={<ValulaParyay />} />



            <Route path="/About-us" element={<AboutUs />} />
            <Route path="/Contact-us" element={<ContactUs />} />


          </Routes>
          <Footer/>
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
// import React from 'react';
// import { Link } from 'react-router-dom'; // Import Link from react-router-dom
// import './TopBelow.css'; 
// import emblame from './img/emb.png';
// import maha_emb from './img/maha-emb.png';
// import logo3 from './img/logo.jpeg';

// const TopBelow = () => {
//   return (
//     <Link to="/" className="mid-wrapper header-bg"> {/* Wrap with Link */}
//       <div className="container">
//         <div className="row align-items-center">
//           <div className="col-md-3 col-sm-4 col-xs-6 text-center">
//             <div className="rotating-image">
//               <img 
//                 src={emblame}
//                 width="110" 
//                 height="110" 
//                 alt="national emblem" 
//               />
//             </div>
//           </div>

//           {/* Center text */}
//           <div className="col-md-6 col-sm-4 col-xs-6 text-center">
//             <div className="emblem-text text-center">
//               <span className="main-title">महाआवास अभियान</span>                
//               <span className="sub-title">GOVERNMENT OF Maharashtra</span>
//             </div>
//           </div>

//           {/* Right side logos */}
//           <div className="col-md-3 col-sm-4 col-xs-6 text-center">
//             <img 
//               alt="Maha Awaas Emblem" 
//               src={maha_emb}
//               className="side-logo"
//             />
//             <img 
//               src={logo3} 
//               alt="Pmayg logo" 
//               className="side-logo"
//             />
//           </div>
//         </div>
//       </div>
//     </Link>
//   );
// };

// export default TopBelow;
// Navbar.js

// TopBelow.js
// TopBelow.js
import React from 'react';
import './TopBelow.css';
import emblame from './img/emb.png';
import maha_emb from './img/maha-emb.png';
import logo3 from './img/logo.jpeg';

const TopBelow = () => {
  return (
    <div className="custom-navbar navbar navbar-fixed-top">
      <div className="navbar-inner">
        <div className="container">
          <div className="row align-items-center">
            {/* Left side logo */}
            <div className="col-6 col-sm-4 text-center text-sm-left">
              <a className="navbar-brand" href="/">
                <img src={maha_emb} alt="MS" className="img-fluid" style={{ height: '100px' }} />
              </a>
            </div>

            {/* Center content */}
            <div className="col-12 col-sm-4 text-center">
              <a className="navbar-brand" href="/">
                <img src={emblame} alt="MS" className="img-fluid" style={{ height: '40px' }} />
                <h5 style={{ fontFamily: 'Arial', fontSize: '12px', color: 'Black' }}>Government of Maharashtra</h5>
                <h5 style={{ fontFamily: 'Arial', fontSize: '20px', color: '#2471A3', fontWeight: 'bold' }}>
                  Rural Development Department
                </h5>
                <h1 style={{ fontFamily: 'Shree Devanagari 714' , fontSize: '28px', color: '#D35400', fontWeight: 'bolder' }}>
                  {/* State Management Unit - Rural Housing */}
                  महा आवास अभियान
                </h1>
              </a>
            </div>

            {/* Right side logo */}
            <div className="col-6 col-sm-4 text-center text-sm-right">
              <br/>
              <img src={logo3} alt="India Flag" className="img-fluid" style={{ width: '100px' }} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopBelow;

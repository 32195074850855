import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const NavinBandhkam = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('NavinBandhkam');
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          'http://145.223.19.41:8090/mahaAawaasReport/api/gruhsankule/findallgruhsankule?page=1&size=100'
        );
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        setData(data);
        setFilteredData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const districts = data && [...new Set(data.map((item) => item.districtName))];
  const talukas = district
    ? [...new Set(data.filter((item) => item.districtName === district).map((item) => item.talukaName))]
    : [];

  const filterData = useCallback(() => {
    const filtered = data.filter((item) => {
      const matchesDistrict = !district || item.districtName === district;
      const matchesTaluka = !taluka || item.talukaName === taluka;
      const matchesSearch = searchTerm
        ? Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : true;

      return matchesDistrict && matchesTaluka && matchesSearch;
    });

    setFilteredData(filtered);
  }, [data, district, taluka, searchTerm]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
    if (selected === 'Gruhsankule') {
      navigate('/GruhSankule');
    } else if (selected === 'bahumajali') {
      navigate('/Bahumajali');
    }
    else if (selected === 'AdarshGharkule') {
      navigate('/AdarshGharkule');
    }
    else if (selected === 'BahumajaliGruhsankule') {
      navigate('/BahumajaliGruhsankule');
    }
    else if (selected === 'CropShop') {
      navigate('/CropShop');
    }
    else if (selected === 'DemoHouseTaluka') {
      navigate('/DemoHouseTaluka');
    }
    else if (selected === 'GharavarLogo') {
      navigate('/GharavarLogo');
    }
    else if (selected === 'GharkulMart') {
      navigate('/GharkulMart');
    }
    else if (selected === 'GruhKarj') {
      navigate('/GruhKarj');
    }
    else if (selected === 'LandBank') {
      navigate('/LandBank');
    }
    else if (selected === 'NavinBandhkam') {
      navigate('/NavinBandhkam');
    }
    else if (selected === 'PrecastTech') {
      navigate('/PrecastTech');
    }
    else if (selected === 'SandBank') {
      navigate('/SandBank');
    }
    else if (selected === 'ValulaParyay') {
      navigate('/ValulaParyay');
    }
  };

  const calculateTotals = () => {
    let totalWorkshops = 0;
    let totalNumbers = 0;
    filteredData.forEach((item) => {
      if (item.allgharkulRajyaYojana) {
        totalWorkshops += parseInt(item.allgharkulRajyaYojana);
      }
      if (item.totalNumber) {
        totalNumbers += parseInt(item.totalNumber);
      }
    });
    return { totalWorkshops, totalNumbers };
  };

  const exportToExcel = () => {
    const mappedData = filteredData.map((item, idx) => ({
      'Sr No': idx + 1,
      District: item.districtName || 'N/A',
      Taluka: item.talukaName || 'N/A',
      'Gruhsankule ID': item.gruhsankuleId,
      'Allgharkul Rajya Yojana': item.allgharkulRajyaYojana,
      'Total Number': item.totalNumber,
      'Created Date': item.createdDatetime,
      Status: item.active ? 'Active' : 'Inactive',
    }));
    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gruhsankule Data');
    XLSX.writeFile(wb, 'Gruhsankule_Data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map((item, idx) => [
      idx + 1,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.gruhsankuleId,
      item.allgharkulRajyaYojana,
      item.totalNumber,
      item.createdDatetime,
      item.active ? 'Active' : 'Inactive',
    ]);

    doc.autoTable({
      head: [
        [
          'Sr No',
          'District',
          'Taluka',
          'Gruhsankule ID',
          'Allgharkul Rajya Yojana',
          'Total Number',
          'Created Date',
          'Status',
        ],
      ],
      body: tableData,
    });
    doc.save('Gruhsankule_Data.pdf');
  };

  const totals = calculateTotals();

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-4">
          <div className="filters p-3" style={{ backgroundColor: '#e6e6fa', borderRadius: '8px' }}>
            <h5>Filters</h5>
            <Form>
            <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="">All Reports</option>
                  <option value="Gruhsankule">Gruhsankule</option>
                  <option value="bahumajali">BahuMajali</option>
                  <option value="AdarshGharkule">AdarshGharkule</option>
                  <option value="BahumajaliGruhsankule">BahumajaliGruhsankule</option>
                  <option value="CropShop">CropShop</option>
                  <option value="DemoHouseTaluka">DemoHouseTaluka</option>
                  <option value="GharavarLogo">GharavarLogo</option>
                  <option value="GharkulMart">GharkulMart</option>
                  <option value="GruhKarj">GruhKarj</option>
                  <option value="GruhSankule">GruhSankule</option>
                  <option value="LandBank">LandBank</option>
                  <option value="NavinBandhkam">NavinBandhkam</option>
                  <option value="PrecastTech">PrecastTech</option>
                  <option value="SandBank">SandBank</option>
                  <option value="ValulaParyay">ValulaParyay</option>



                </Form.Control>
              </Form.Group>

              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control as="select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                  <option value="">All Districts</option>
                  {districts.map((districtName, idx) => (
                    <option key={idx} value={districtName}>
                      {districtName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  as="select"
                  value={taluka}
                  onChange={(e) => setTaluka(e.target.value)}
                  disabled={!district}
                >
                  <option value="">All Talukas</option>
                  {talukas.map((talukaName, idx) => (
                    <option key={idx} value={talukaName}>
                      {talukaName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <h4>Gruhsankule Report</h4>
            <div className="d-flex align-items-center">
              <InputGroup className="me-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" className="me-2" onClick={exportToExcel}>
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>
                Export to PDF
              </Button>
            </div>
          </div>

          {loading ? (
            <Spinner animation="border" className="d-block mx-auto" />
          ) : (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 1 }}>
                  <tr>
                    <th>Sr No</th>
                    <th>District</th>
                    <th>Taluka</th>
                    <th>Allgharkul Rajya Yojana</th>
                    <th>Total Number</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    <>
                      <tr>
                        <td colSpan="3" className="text-right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>{totals.totalWorkshops}</strong>
                        </td>
                        <td>
                          <strong>{totals.totalNumbers}</strong>
                        </td>
                        <td colSpan="2"></td>
                      </tr>
                      {filteredData.map((item, idx) => (
                        <tr key={idx}>
                          <td>{idx + 1}</td>
                          <td>{item.districtName || 'N/A'}</td>
                          <td>{item.talukaName || 'N/A'}</td>
                          <td>{item.allgharkulRajyaYojana}</td>
                          <td>{item.totalNumber}</td>
                          <td>{item.active ? 'Active' : 'Inactive'}</td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan="3" className="text-right">
                          <strong>Total</strong>
                        </td>
                        <td>
                          <strong>{totals.totalWorkshops}</strong>
                        </td>
                        <td>
                          <strong>{totals.totalNumbers}</strong>
                        </td>
                        <td colSpan="2"></td>
                      </tr>
                    </>
                  ) : (
                    <tr>
                      <td colSpan="7" className="text-center">
                        No records found
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default NavinBandhkam;

import React, { useState } from 'react';
import { FaHome, FaBars, FaTimes, FaUserAlt, FaInfoCircle, FaCamera } from 'react-icons/fa';
import './Navbar.css'; // Import the updated CSS styles

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen); // Toggle the menu state
  };

  return (
    <nav className="navbarr navbar">
      <a href="/" className="logo"></a> 
      
      <div className="hamburger" onClick={toggleMenu}>
        {isOpen ? <FaTimes /> : <FaBars />} {/* Toggle the icon */}
      </div>

      <div className={`menu ${isOpen ? 'open' : ''}`}> {/* Toggle the 'open' class */}
        <a href="/" className="menu-item"><FaHome className="icon" /> Home</a>
        <a href="#gallary" className="menu-item"><FaCamera className="icon" /> Gallery</a>
        <a href="http://145.223.19.41:8081/maa/login" className="menu-item"><FaUserAlt className="icon" /> Login</a>
        <a href="/About-us" className="menu-item"><FaInfoCircle className="icon" /> About Us</a>
        <a href="/Contact-us" className="menu-item"><FaInfoCircle className="icon" />Contact Us</a>

      </div>
    </nav>
  );
};

export default Navbar;

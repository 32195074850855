import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Spinner, Alert, Row, Col } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const CapacityBuilding = () => {
  const [capacityData, setCapacityData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [selectedDistrict, setSelectedDistrict] = useState('');
  const [talukaData, setTalukaData] = useState([]);
  const [selectedTaluka, setSelectedTaluka] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [exportSuccess, setExportSuccess] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(''); // Manage selected filter state

  const navigate = useNavigate();
  const apiBaseUrl = process.env.REACT_APP_API_URL;

  // Fetch initial capacity building data
  useEffect(() => {
    const fetchCapacityData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${apiBaseUrl}/capacitybuilding/CapacityBuilding`);
        if (!response.ok) throw new Error('Failed to fetch capacity building data');
        const data = await response.json();
        setCapacityData(data || []);
        setFilteredData(data || []);

        const uniqueDistricts = [...new Set(data.map((item) => item.district))];
        setDistricts(uniqueDistricts);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCapacityData();
  }, [apiBaseUrl]);

  // Fetch additional taluka count data based on the selected district
  useEffect(() => {
    if (selectedDistrict) {
      const fetchTalukaData = async () => {
        try {
          const response = await fetch(`${apiBaseUrl}/capacitybuilding/CapacityBuilding/TalCount?district=${selectedDistrict}`);
          if (!response.ok) throw new Error('Failed to fetch taluka data');
          const data = await response.json();
          setTalukaData(data || []);
        } catch (err) {
          setError(err.message);
        }
      };

      fetchTalukaData();
    } else {
      setTalukaData([]); // Clear taluka data when no district is selected
    }
  }, [selectedDistrict, apiBaseUrl]);

  useEffect(() => {
    let filteredData = capacityData;
    if (selectedDistrict) {
      filteredData = filteredData.filter((item) => item.district === selectedDistrict);
    }
    if (selectedTaluka) {
      filteredData = filteredData.filter((item) => item.taluka === selectedTaluka);
    }
    setFilteredData(filteredData);
  }, [selectedDistrict, selectedTaluka, capacityData]);

  const handleDistrictChange = (event) => {
    setSelectedDistrict(event.target.value);
  };

  const handleTalukaChange = (event) => {
    setSelectedTaluka(event.target.value);
  };

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);

    // Redirect based on the selected filter
    if (selected === '') {
      navigate('/CapacityBuilding'); // Navigate to the main CapacityBuilding page
    } else if (selected === 'Workshop') {
      navigate('/Workshop'); // Navigate to the Workshop page
    } else if (selected === 'Mason Training') {
      navigate('/masonTraining'); // Navigate to the Mason Training page
    } else if (selected === 'Beneficiary Meet') {
      navigate('/beneficiaryMelave'); // Navigate to the Beneficiary Meet page
    }
  };

  const exportToExcel = (data, filename) => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'CapacityBuilding');
    XLSX.writeFile(wb, `${filename}.xlsx`);
    setExportSuccess(true);
    setTimeout(() => setExportSuccess(false), 3000);
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    doc.text("Capacity Building Report", 14, 16);
    doc.autoTable({
      head: [['Sr.No', 'District Name', 'Workshop Capacity Building', 'Beneficiary Melava', 'Mason Training']],
      body: filteredData.map((data, index) => [
        index + 1,
        data.district,
        data.tbl_workshop_capacity_build,
        data.tbl_beneficiary_melava,
        data.tbl_mason_training
      ]),
    });
    doc.save('CapacityBuilding.pdf');
  };

  const calculateTotals = () => {
    let totalWorkshops = 0;
    let totalBeneficiaryMelava = 0;
    let totalMasonTraining = 0;

    filteredData.forEach(data => {
      totalWorkshops += parseInt(data.tbl_workshop_capacity_build) || 0;
      totalBeneficiaryMelava += parseInt(data.tbl_beneficiary_melava) || 0;
      totalMasonTraining += parseInt(data.tbl_mason_training) || 0;
    });

    return { totalWorkshops, totalBeneficiaryMelava, totalMasonTraining };
  };

  const headers = ['Sr.No', 'जिल्हा', 'कार्यशाळा क्षमता निर्माण', 'लाभार्थी मेळावा', 'मास्टर ट्रेनिंग'];

  const renderTable = () => {
    if (loading) return <div style={{ textAlign: 'center', marginTop: '50px' }}><Spinner animation="border" /></div>;
    if (error) return <Alert variant="danger">Error: {error}</Alert>;

    const { totalWorkshops, totalBeneficiaryMelava, totalMasonTraining } = calculateTotals();

    return (
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            {headers.map((header, idx) => (
              <th key={idx} style={{ textAlign: 'center' }}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalWorkshops}</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalBeneficiaryMelava}</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalMasonTraining}</strong></td>
          </tr>
          {filteredData.map((data, idx) => (
            <tr key={idx}>
              <td>{idx + 1}</td>
              <td>{data.district}</td>
              <td>{data.tbl_workshop_capacity_build}</td>
              <td>{data.tbl_beneficiary_melava}</td>
              <td>{data.tbl_mason_training}</td>
            </tr>
          ))}
          <tr>
            <td colSpan={2} style={{ textAlign: 'left' }}><strong>Total</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalWorkshops}</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalBeneficiaryMelava}</strong></td>
            <td style={{ textAlign: 'left' }}><strong>{totalMasonTraining}</strong></td>
          </tr>
        </tbody>
      </Table>
    );
  };

  const renderTalukaTable = () => {
    if (!talukaData || talukaData.length === 0) return null;

    return (
      <div className="mt-4">
        <h5>Taluka Count for {selectedDistrict}</h5>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Sr.No</th>
              <th>Taluka Name</th>
              <th>Workshop Capacity Building</th>
              <th>Beneficiary Melava</th>
              <th>Mason Training</th>
            </tr>
          </thead>
          <tbody>
            {talukaData.map((taluka, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{taluka.taluka}</td>
                <td>{taluka.tbl_workshop_capacity_build}</td>
                <td>{taluka.tbl_beneficiary_melava}</td>
                <td>{taluka.tbl_mason_training}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    );
  };

  return (
    <div className="container mt-4">
      <Row>
        <Col xs={12} md={2}>
          <div className="p-3" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                  <option value="">All Reports</option>
                  <option value="Workshop">Workshop</option>
                  <option value="Mason Training">Mason Training</option>
                  <option value="Beneficiary Meet">Beneficiary Meet</option>
                </Form.Control>
              </Form.Group>
              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>Select District</Form.Label>
                <Form.Control as="select" value={selectedDistrict} onChange={handleDistrictChange}>
                  <option value="">All Districts</option>
                  {districts.map((district, idx) => (
                    <option key={idx} value={district}>
                      {district}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              {selectedDistrict && (
                <Form.Group controlId="talukaSelect" className="mb-3">
                  <Form.Label>Select Taluka</Form.Label>
                  <Form.Control as="select" value={selectedTaluka} onChange={handleTalukaChange}>
                    <option value="">All Talukas</option>
                    {talukaData.map((taluka, idx) => (
                      <option key={idx} value={taluka.taluka}>
                        {taluka.taluka}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              )}
            </Form>
          </div>
        </Col>
        <Col xs={12} md={10}>
          <div className="d-flex justify-content-between mb-3">
            <h3>Capacity Building Report</h3>
            <div className="d-flex">
              <Button variant="primary" onClick={() => exportToExcel(filteredData, 'CapacityBuildingData')} className="me-2">Export to Excel</Button>
              <Button variant="success" onClick={exportToPDF}>Export to PDF</Button>
            </div>
          </div>
          {renderTable()}
          {renderTalukaTable()}
        </Col>
      </Row>
    </div>
  );
};

export default CapacityBuilding;

import React, { useState, useEffect, useCallback } from 'react';
import { Table, Spinner, Form, Container, Row, Col, Button, InputGroup, FormControl } from 'react-bootstrap';
import { FaSearch } from 'react-icons/fa';
import { jsPDF } from 'jspdf';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';

const GrampanchayatSes = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [district, setDistrict] = useState('');
  const [taluka, setTaluka] = useState('');
  const [selectedFilter, setSelectedFilter] = useState('GrampanchayatSes');
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        console.log('Fetching Grampanchayat Ses data...');
        const response = await fetch(
          'http://145.223.19.41:8090/mahaAawaasReport/api/convergence/grampanchayatSes?page=1&size=100'
        );
        if (!response.ok) {
          throw new Error(`Error: ${response.statusText} (Status: ${response.status})`);
        }
        const result = await response.json();
        console.log('Fetched data:', result);
        setData(result);
        setFilteredData(result);
      } catch (error) {
        console.error('Error fetching data:', error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const districts = data && [...new Set(data.map((item) => item.districtName))];
  const talukas = district
    ? [...new Set(data.filter((item) => item.districtName === district).map((item) => item.talukaName))]
    : [];

  const filterData = useCallback(() => {
    const filtered = data.filter((item) => {
      const matchesDistrict = !district || item.districtName === district;
      const matchesTaluka = !taluka || item.talukaName === taluka;
      const matchesSearch = searchTerm
        ? Object.values(item)
            .join(' ')
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        : true;

      return matchesDistrict && matchesTaluka && matchesSearch;
    });

    setFilteredData(filtered);
  }, [data, district, taluka, searchTerm]);

  useEffect(() => {
    filterData();
  }, [filterData]);

  const handleFilterChange = (event) => {
    const selected = event.target.value;
    setSelectedFilter(selected);
    if (selected === 'allReports') {
      navigate('/Convergence');
    }else if (selected === 'SwachhBharatAbhiyan') {
      navigate('/SwachhBharatAbhiyan');
    } else if (selected === 'JaljivanMission') {
      navigate('/JaljivanMission');
    } else if (selected === 'DalitVastiSudharYojana') {
      navigate('/DalitVastiSudharYojana');
    } else if (selected === 'GraminJivannotyaAbhiyan') {
      navigate('/GraminJivannotyaAbhiyan');
    } else if (selected === 'GrampanchayatSes') {
      navigate('/GrampanchayatSes');
    } else if (selected === 'JilhaParishadSes') {
      navigate('/JilhaParishadSes');
    } else if (selected === 'PanchayatSamitiSes') {
      navigate('/PanchayatSamitiSes');
    } else if (selected === 'SaubhagyaYojana') {
      navigate('/SaubhagyaYojana');
    } else if (selected === 'ThakkarBappaYojana') {
      navigate('/ThakkarBappaYojana');
    }
  };

  const calculateTotals = () => {
    let totalWorkshops = 0;
    let totalNumbers = 0;
    filteredData.forEach((item) => {
      if (item.allgharkulRajyaYojana) {
        totalWorkshops += parseInt(item.allgharkulRajyaYojana);
      }
      if (item.totalNumber) {
        totalNumbers += parseInt(item.totalNumber);
      }
    });
    return { totalWorkshops, totalNumbers };
  };

  const exportToExcel = () => {
    const mappedData = filteredData.map((item, idx) => ({
      'Sr No': idx + 1,
      District: item.districtName || 'N/A',
      Taluka: item.talukaName || 'N/A',
      'Grampanchayat ID': item.grampanchayatId,
      'Allgharkul Rajya Yojana': item.allgharkulRajyaYojana,
      'Total Number': item.totalNumber,
      'Created Date': item.createdDatetime,
      Status: item.active ? 'Active' : 'Inactive',
    }));
    const ws = XLSX.utils.json_to_sheet(mappedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Grampanchayat Ses Data');
    XLSX.writeFile(wb, 'Grampanchayat_Ses_Data.xlsx');
  };

  const exportToPDF = () => {
    const doc = new jsPDF();
    const tableData = filteredData.map((item, idx) => [
      idx + 1,
      item.districtName || 'N/A',
      item.talukaName || 'N/A',
      item.grampanchayatId,
      item.allgharkulRajyaYojana,
      item.totalNumber,
      item.createdDatetime,
      item.active ? 'Active' : 'Inactive',
    ]);

    doc.autoTable({
      head: [
        [
          'Sr No',
          'District',
          'Taluka',
          'Grampanchayat ID',
          'Allgharkul Rajya Yojana',
          'Total Number',
          'Created Date',
          'Status',
        ],
      ],
      body: tableData,
    });
    doc.save('Grampanchayat_Ses_Data.pdf');
  };

  const totals = calculateTotals();

  return (
    <Container fluid>
      <Row>
        <Col md={2} className="p-4">
          <div className="filters p-3" style={{ backgroundColor: '#e6e6fa', borderRadius: '8px' }}>
            <h5>Filters</h5>
            <Form>
              <Form.Group controlId="filterSelect" className="mb-3">
                <Form.Label>Select Report</Form.Label>
                <Form.Control as="select" value={selectedFilter} onChange={handleFilterChange}>
                <option value="allReports">All Reports</option>
                <option value="SwachhBharatAbhiyan">Swachh Bharat Abhiyan</option>
                  <option value="UjjwalaYojana">UjjwalaYojana</option>
                  <option value="JaljivanMission">JaljivanMission</option>
                  <option value="DalitVastiSudharYojana">DalitVastiSudharYojana</option>
                  <option value="GraminJivannotyaAbhiyan">GraminJivannotyaAbhiyan</option>
                  <option value="GrampanchayatSes">GrampanchayatSes</option>
                  <option value="JilhaParishadSes">JilhaParishadSes</option>
                  <option value="PanchayatSamitiSes">PanchayatSamitiSes</option>
                  <option value="SaubhagyaYojana">SaubhagyaYojana</option>
                  <option value="ThakkarBappaYojana">ThakkarBappaYojana</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="districtSelect" className="mb-3">
                <Form.Label>District</Form.Label>
                <Form.Control as="select" value={district} onChange={(e) => setDistrict(e.target.value)}>
                  <option value="">All Districts</option>
                  {districts.map((districtName, idx) => (
                    <option key={idx} value={districtName}>
                      {districtName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="talukaSelect" className="mb-3">
                <Form.Label>Taluka</Form.Label>
                <Form.Control
                  as="select"
                  value={taluka}
                  onChange={(e) => setTaluka(e.target.value)}
                  disabled={!district}
                >
                  <option value="">All Talukas</option>
                  {talukas.map((talukaName, idx) => (
                    <option key={idx} value={talukaName}>
                      {talukaName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            </Form>
          </div>
        </Col>

        <Col md={10} className="p-4">
          <div className="d-flex justify-content-between mb-3">
            <h4>Grampanchayat Ses Report</h4>
            <div className="d-flex align-items-center">
              <InputGroup className="me-3">
                <FormControl
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <InputGroup.Text>
                  <FaSearch />
                </InputGroup.Text>
              </InputGroup>
              <Button variant="primary" className="me-2" onClick={exportToExcel}>
                Export to Excel
              </Button>
              <Button variant="success" onClick={exportToPDF}>
                Export to PDF
              </Button>
            </div>
          </div>

          {loading ? (
            <Spinner animation="border" className="d-block mx-auto" />
          ) : (
            <div style={{ maxHeight: '400px', overflowY: 'auto' }}>
              <Table striped bordered hover responsive>
                <thead style={{ position: 'sticky', top: 0, backgroundColor: '#f8f9fa', zIndex: 1 }}>
                  <tr>
                    <th>Sr No</th>
                    <th>District</th>
                    <th>Taluka</th>
                    <th>Allgharkul Rajya Yojana</th>
                    <th>Total Number</th>
                    <th>Created Date</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    filteredData.map((item, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{item.districtName || 'N/A'}</td>
                        <td>{item.talukaName || 'N/A'}</td>
                        <td>{item.allgharkulRajyaYojana}</td>
                        <td>{item.totalNumber}</td>
                        <td>{item.createdDatetime}</td>
                        <td>{item.active ? 'Active' : 'Inactive'}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No records found.</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          )}

          <div className="d-flex justify-content-end mt-3">
            <strong>
              Total Workshops: {totals.totalWorkshops} | Total Numbers: {totals.totalNumbers}
            </strong>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default GrampanchayatSes;

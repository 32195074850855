import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import { FaTimes } from 'react-icons/fa';
import './iec.css';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5009/api';

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
  autoplay: true,
  autoplaySpeed: 2000,
  pauseOnHover: true,
};

const IECComponent = () => {
  const [images, setImages] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [activeCategory, setActiveCategory] = useState(null);
  const [fullImages, setFullImages] = useState([]);

  const categories = [
    'hoarding',
    'banner',
    'bhoomiPoojan',
    'brochure',
    'gruhpravesh',
    'ipc',
    'pamphlet',
    'poster',
    'priceDistribution',
    'standees',
    'womensDayCelebration',
  ];

  useEffect(() => {
    const fetchImages = async () => {
      const fetchedImages = {};
      try {
        const fetchPromises = categories.map((category) =>
          fetch(`${API_BASE_URL}/iec/images/${category}`)
            .then((response) => response.json())
            .then((imageUrls) => {
              fetchedImages[category] = imageUrls;
            })
            .catch((error) => {
              console.error(`Error fetching images for ${category}:`, error);
              fetchedImages[category] = [];
            })
        );

        await Promise.all(fetchPromises);

        setImages(fetchedImages);
      } catch (error) {
        setError('Something went wrong while loading images.');
        console.error('Error fetching images:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchImages();
  }, []);

  const handleCategoryClick = (category) => {
    setActiveCategory(category);
    setFullImages(images[category] || []);
  };

  const closeSlider = () => {
    setActiveCategory(null);
  };

  return (
    <div className="iec-component">
      {loading && (
        <div className="loading-overlay">
          <div className="spinner"></div>
        </div>
      )}
      {error && (
        <div className="error-message">
          <p>{error}</p>
        </div>
      )}

      <div className="categories">
        {/* <h2 className="categories-heading">Explore Our Categories</h2> */}
        <div className="category-thumbnails">
          {categories.map((category) => (
            <div
              key={category}
              className="category-card"
              onClick={() => handleCategoryClick(category)}
            >
              <div className="category-card-header">
                <h4 className="category-name">{category}</h4>
              </div>
              <div className="category-thumbnail">
                <img
                  src={images[category] && images[category][0]}
                  alt={category}
                  className="thumbnail-image"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {activeCategory && (
        <div className="slider-modal" onClick={closeSlider}>
          <div className="slider-container" onClick={(e) => e.stopPropagation()}>
            <Slider {...settings}>
              {fullImages.map((imageUrl, index) => (
                <div key={index} className="iec-slider-item">
                  <img
                    src={imageUrl}
                    alt={`Image for category: ${activeCategory}`}
                    className="iec-image"
                  />
                </div>
              ))}
            </Slider>
            <button className="close-slider" onClick={closeSlider} aria-label="Close slider">
              <FaTimes />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default IECComponent;
